import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ICompanyServices } from 'services/company_services';
import { createCompanypresenter } from 'stores/company_store';
import { IUserServices } from 'services/user_services';
import * as rootStore from 'stores/root_store';

import styles from './login.module.css';
import imesLogo from 'assets/logo.png';
import isdLogo from 'assets/isdcorp_logo.png';
import ttfLogo from 'assets/ttf_logo.png';

import { FaEnvelope, FaLock } from 'react-icons/fa';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import { RiBuilding2Fill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { TbLoaderQuarter } from 'react-icons/tb';

interface FormLoginModel {
    userName: string;
    password: string;
    companyCode: string;
    saleOrg: string;
}

// validation foem bằng thư viện yup
const schema = yup.object().shape({
    userName: yup.string().required('Tên tài khoản không được để trống!'),
    password: yup.string().required('Mật khẩu không được để trống!'),
});

export function createLogin(
    companyService: { companyServices: ICompanyServices },
    userService: { userServices: IUserServices },
) {
    // khởi tạo presenter
    // const userPresenter = createUserPresenter(userService);
    // const userStore = userPresenter.createStore();
    const userPresenter = rootStore.userPresenter;
    const userStore = rootStore.userStore;
    const companyPresenter = createCompanypresenter(companyService);
    const companyStore = companyPresenter.createStore();

    return observer(() => {
        document.title = 'Đăng nhập hệ thống';
        const [selectCompany, setSelectCompanyName] = useState<string>('');
        const [userName, setUserName] = useState<string>('');
        const [showPassword, setShowPassword] = useState<boolean>(false);
        const [loading, setLoading] = useState<boolean>(false);
        const navigate = useNavigate();
        // ========== Yup validate ===============
        const {
            register,
            handleSubmit,
            formState: { errors },
        } = useForm<FormLoginModel>({
            resolver: yupResolver(schema),
        });

        // ========= Get list Company =========
        useEffect(() => {
            const checkAccount = async () => {
                await userPresenter.checkAccount(userStore, userName);
                await companyPresenter.geListtCompany(companyStore, userName);
                if (userStore.isVendor) {
                    setSelectCompanyName(companyStore.companies[0].companyCode);
                }
            };
            checkAccount();
        }, [userName]);

        // khi chọn company thì lấy ra danh sách SaleORG theo company đã chọn
        useEffect(() => {
            companyPresenter.getListSaleORG(companyStore, userName, selectCompany);
        }, [selectCompany]);

        // Khi tab hoặc click ra ngoài thì setState(userName) và gửi request
        // dùng userName để lấy ra danh sách company
        const handleChangeUserName = (e: React.ChangeEvent<HTMLInputElement>) => {
            setUserName(e.target.value);
        };
        // chọn company từ thẻ select
        const handleSelectCompany = (e: React.ChangeEvent<HTMLSelectElement>) => {
            setSelectCompanyName(e.target.value);
        };

        // =================== Show hide password =======================
        // hàm show và hide password. nếu đang show thì hide và ngược lại
        const handleShowPassword = () => {
            setShowPassword((pre) => !pre);
        };

        // ================== Submit login form =================
        const onLoginSubmit = async (value: FormLoginModel) => {
            setLoading(true);
            value.companyCode = selectCompany;
            value.saleOrg = companyStore.saleOrg && companyStore.saleOrg[0].saleOrgCode;
            const result = await userPresenter.login(userStore, value);
            if (userStore.userRespone.isSuccess) {
                navigate('/');
            } else {
                toast.error(userStore.userRespone.message);
            }
            setLoading(false);
        };

        return (
            <div className={styles.wrapper}>
                <div className={`shadow-2xl flex max-w-4xl rounded-2xl bg-white ${styles.containerWidth}`}>
                    <div className="rounded-2xl xl:w-1/2 md:w-1/2 w-full p-14 bg-white">
                        <div className="w-full flex justify-between mb-6 items-end">
                            <img src={ttfLogo} alt="TTF Logo" className="h-6" />
                            <img src={isdLogo} alt="ISDCorp Logo" className="h-8" />
                        </div>
                        <form onSubmit={handleSubmit(onLoginSubmit)} className="space-y-4">
                            <h3 className="text-center text-3xl font-bold text-primary mb-12">
                                Đăng nhập
                                <span className="w-16 h-1 block bg-primary mx-auto mt-3"></span>
                            </h3>
                            <div className="relative text-gray-500">
                                <input
                                    {...register('userName')}
                                    type="text"
                                    id="userName"
                                    onBlur={handleChangeUserName}
                                    placeholder="Tên tài khoản"
                                    className="peer w-full border-2 border-gray-200 py-2 rounded-lg pr-3 pl-14 bg-transparent outline-none placeholder-slate-400 focus:border-blue-300"
                                />
                                <label
                                    htmlFor="userName"
                                    className="peer-focus:text-blue-400 absolute mx-5 text-gray-400 top-1/2 left-0 translate-y-[-50%]"
                                >
                                    <FaEnvelope />
                                </label>
                            </div>
                            {/* thông báo lỗi khi userName không hợp lệ */}
                            {errors.userName && (
                                <p className="!mt-1 text-xs text-red-500 pl-5">{`${errors.userName.message}`}</p>
                            )}
                            <div className="relative text-gray-500">
                                <input
                                    {...register('password')}
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    placeholder="Mật khẩu"
                                    className="peer w-full border-2 border-gray-200 py-2 rounded-lg pr-12 pl-14 bg-transparent outline-none placeholder-slate-400 focus:border-blue-300"
                                />
                                <label
                                    htmlFor="password"
                                    className="peer-focus:text-blue-400 absolute mx-5 text-gray-400 top-1/2 left-0 translate-y-[-50%]"
                                >
                                    <FaLock />
                                </label>
                                {/* set icon cho sự kiện show và hide password */}
                                {showPassword ? (
                                    <button type="button">
                                        <AiOutlineEye
                                            onClick={handleShowPassword}
                                            className="peer-focus:text-blue-400 absolute right-0 top-1/2 translate-y-[-50%] mx-5 text-xl cursor-pointer text-gray-400"
                                        />
                                    </button>
                                ) : (
                                    <button type="button">
                                        <AiOutlineEyeInvisible
                                            onClick={handleShowPassword}
                                            className="peer-focus:text-blue-400 absolute right-0 top-1/2 translate-y-[-50%] mx-5 text-xl cursor-pointer text-gray-400"
                                        />
                                    </button>
                                )}
                            </div>
                            {/* thông báo lỗi khi password không hợp lệ */}
                            {errors.password && (
                                <p className="!mt-1 text-xs text-red-500 pl-5">{`${errors.password.message}`}</p>
                            )}

                            {/* nếu có userName và công ty không rỗng thì hiện chọn công ty */}
                            {!userStore.isVendor && userName && companyStore.companies ? (
                                <div className="text-gray-500 relative w-full  border-2 border-gray-200 flex items-center rounded-lg overflow-hidden">
                                    <RiBuilding2Fill className="absolute mx-5 text-gray-400" />
                                    <select
                                        onChange={handleSelectCompany}
                                        className="w-full py-2 pl-14 mr-3 focus-visible:outline-none"
                                    >
                                        <option className="hidden">-- Chọn công ty --</option>
                                        {companyStore.companies.map((company, index) => {
                                            return (
                                                <option value={company.companyCode} key={index}>
                                                    {company.companyName}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            ) : (
                                ''
                            )}
                            <div className="w-full flex items-center">
                                <label className="w-full flex">
                                    <input type="checkbox" className={styles.checkBox} />
                                    <h3 className="text-gray-400 text-sm font-medium ml-3">Nhớ thông tin đăng nhập</h3>
                                </label>
                            </div>
                            <button
                                type="submit"
                                disabled={loading}
                                className={`w-full text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center focus:outline-none ${
                                    loading ? 'bg-blue-700/80' : 'bg-blue-700 hover:bg-blue-600'
                                }`}
                            >
                                {loading ? (
                                    <span className="flex items-center justify-center">
                                        <TbLoaderQuarter className="animate-spin bg" />
                                        Đăng nhập...
                                    </span>
                                ) : (
                                    <span>Đăng nhập</span>
                                )}
                            </button>
                        </form>
                    </div>
                    <div
                        className={`relative w-1/2 bg-primary rounded-2xl border-r-2 border-white hidden xl:flex md:flex sm:hiden flex-col justify-center items-center p-5 py-24 ${styles.shadow}`}
                    >
                        <div className="w-4/5">
                            <img src={imesLogo} className="w-full h-full" />
                        </div>
                        <h3 className="text-white text-3xl font-bold tracking-[0.25em]">Web Portal</h3>
                        <span
                            className={`absolute w-10 h-10 bg-white rounded-full top-9 right-9 shadow-zinc-800 border-l-2 ${styles.shadowInner} border-white`}
                        ></span>
                    </div>
                </div>
            </div>
        );
    });
}
