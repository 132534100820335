import { forwardRef } from "react";
import { PurchaseOrderDetails } from "services/barcode_service";
import { BASE_API_BARCODE } from "utils/constants";

type ContentToPrintProps = {
  barcodePath: string;
  vendorName: string;
  productCode: string;
  productName: string;
  poCode: PurchaseOrderDetails[];
  sowps: Array<any>;
  listLSXDT: string[];
  batch: any;
  rawMaterialCardId: number;
};

const ContentToPrint = forwardRef<HTMLDivElement, ContentToPrintProps>((props, ref) => {
  return (
    <div ref={ref} className="flex border-gray-500 p-5 m-3 mt-16 items-start w-sceen backdrop-blur-sm text-lg">
      {/* Top info */}
      {/* Main content */}
      <div className="relative mr-14 w-2/12">
        <div className="border p-2">
          <img className="w-full object-contain" src={`${BASE_API_BARCODE}${props.barcodePath}`} />
        </div>
        <p className="absolute left-1/2 bottom-[-30px] translate-x-[-50%] whitespace-nowrap text-sm">
          {props.rawMaterialCardId}
        </p>
      </div>
      <div className="flex flex-col">
        <div className="flex">
          <span className="min-w-[120px] block">Tên NCC:</span>
          <span className="block">{props.vendorName}</span>
        </div>
        <div className="flex">
          <span className="min-w-[120px] block">Số PO :</span>
          <div className="">
            {props.poCode.map((item, index) => (
              <span className="block" key={index}>
                {item.po}/{item.poLine} (
                {" " + item.quantity.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " " + item.unit})
              </span>
            ))}
          </div>
        </div>
        <div className="flex">
          <span className="min-w-[120px] block">Batch :</span>
          <span className="block">{props.batch}</span>
        </div>
        <div className="flex">
          <span className="min-w-[120px] block">Mã hàng :</span>
          <span className="block">{props.productCode}</span>
        </div>
        <div className="flex">
          <span className="min-w-[120px] block">Tên hàng :</span>
          <span className="block">{props.productName}</span>
        </div>
        <div className="flex">
          <span className="min-w-[100px] md:mr-10 block">LSXĐT :</span>
          <span
            className="block"
            dangerouslySetInnerHTML={{
              __html: props.listLSXDT.join("</br>"),
            }}
          ></span>
        </div>
        <div className="flex">
          <span className="min-w-[120px] block">SO/WBS :</span>
          <span className="block">
            {props.sowps.map((item, index) => (
              <span key={index} className="block">
                {`${item.so || item.so !== "" ? item.so : ""}${
                  item.soLine || item.soLine !== "" ? "" : "/" + item.soLine
                }${item.wbs || item.wbs !== "" ? item.wbs : ""} `}
              </span>
            ))}
          </span>
        </div>
      </div>
    </div>
  );
});

export default ContentToPrint;
