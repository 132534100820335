import { useState } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { ChildMenu } from 'services/user_services';

type MenuItemProps = {
    open: boolean;
    menuId: string;
    menuName: string;
    icon: string;
    childMenu: Array<ChildMenu>;
};

const MenuItem = ({ open, menuId, menuName, icon, childMenu }: MenuItemProps) => {
    const [submenuOpen, setSubmenuOpen] = useState(false);

    const handleDropdown = () => {
        if (open) {
            setSubmenuOpen(!submenuOpen);
        }
    };
    return (
        <>
            <li
                onClick={handleDropdown}
                className="text-gray-300 text-default flex items-center gap-x-4 cursor-pointer py-3 px-4 border-l-4 border-transparent hover:border-primary hover:bg-gray-700 mt-2 "
            >
                <span className="text-md block float-left select-none">
                    <i className={icon} />
                </span>
                {/* nếu side bar đang đóng thì ẩn các items */}
                <span className={`text-default flex-1 whitespace-nowrap ${!open && 'hidden'} duration-400`}>
                    {menuName}
                </span>
                {/* nếu side bar đang mở thì hiện nút dropdown */}
                {/* nếu submenu mở thì dropdowm icon hướng lên trên (rotate 180 deg) */}
                {open && <HiChevronDown className={`duration-200 ${submenuOpen && 'rotate-180'}`} />}
            </li>
            {submenuOpen && open && (
                <ul className="bg-gray-700">
                    {childMenu.map((item: ChildMenu) => {
                        if (item.menuId === menuId)
                            return (
                                <Link to={item.pageUrl} className="w-full" key={item.menuId}>
                                    <li className="text-gray-300 text-xs flex items-center gap-x-4 cursor-pointer select-none p-2 px-5 border-l-4 border-transparent hover:border-primary hover:bg-gray-600 mt-2">
                                        <span>{item.pageName}</span>
                                    </li>
                                </Link>
                            );
                    })}
                </ul>
            )}
        </>
    );
};

export default MenuItem;
