export const COMPANY_ROUTES = {
    BASE: 'company/*',
    LIST_COMPANY: {
        LINK: 'company/',
        PATH: '',
    },
    NEW_COMPANY: {
        LINK: 'company/new',
        PATH: 'new',
    },
};

export const ROUTES = {
    HOME: '/*',
    ABOUT: {
        BASE: 'about/*',
        LINK: 'about',
        PATH: 'about',
    },
    LOGIN: {
        BASE: 'login',
    },
    PURCHASE_ORDER: {
        BASE: 'MES/PurchaseOrder/*',
    },
    PURCHASE_ORDER_DETAIL: {
        BASE: 'MES/PurchaseOrder/detail',
    },
    PRINT_BARCODE: {
        BASE: 'MES/PurchaseOrder/printBarCode',
    },
    HISTORY: {
        BASE: 'MES/PurchaseHistory',
    },
    HISTORY_DETAIL: {
        BASE: 'MES/PurchaseHistory/:rawMaterialCardId',
    },
    CHANGE_PASSWORD: {
        BASE: 'Permission/Auth/ChangePassword',
    },
};
