import { createUserServices, IUserServices } from './user_services';
import { createPurchaseOrderServices, IPurchaseOrderServices } from './purchaseOrder_services';
import { createCompanyServices, ICompanyServices } from './company_services';
import { createPrintBarCodeServices, IBarcodeServices } from './barcode_service';
import { createDeliveryHistoryServices, IDeliveryHistoryServices } from './deliveryHistory_services';

export interface IServices {
    userServices: IUserServices;
    purchaseOrderServices: IPurchaseOrderServices;
    companyServices: ICompanyServices;
    barcodeServices: IBarcodeServices;
    deliveryHistoryServices: IDeliveryHistoryServices;
}

export function createServices(url: string): IServices {
    return {
        userServices: createUserServices(url),
        purchaseOrderServices: createPurchaseOrderServices(url),
        companyServices: createCompanyServices(url),
        barcodeServices: createPrintBarCodeServices(url),
        deliveryHistoryServices: createDeliveryHistoryServices(),
    };
}
