import React from 'react';

const Layout = ({
    header,
    footer,
    sidebar,
    content,
}: {
    header: React.ReactNode;
    footer: React.ReactNode;
    sidebar: React.ReactNode;
    content: React.ReactNode;
}) => {
    return (
        <div className="flex relative">
            {sidebar}
            <div className="w-full overflow-y-auto flex flex-col justify-between bg-gray-200">
                <div>
                    {header}
                    {content}
                </div>
                {footer}
            </div>
        </div>
    );
};

export type LayoutHOC = <T = {}>(Content: React.ComponentType<T>) => React.ComponentType<T>;

export function createLoginLayout() {
    return function withLayout<T = {}>(Content: React.ComponentType<T>) {
        return (props: T) => <Layout header={null} footer={null} sidebar={null} content={<Content {...props} />} />;
    };
}

export function createDefaultLayout(
    Header: React.ComponentType,
    Sidebar: React.ComponentType,
    Footer: React.ComponentType,
) {
    return function withLayout<T = {}>(Content: React.ComponentType<T>) {
        return (props: T) => (
            <Layout header={<Header />} footer={<Footer />} sidebar={<Sidebar />} content={<Content {...props} />} />
        );
    };
}
