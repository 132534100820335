import { observable, runInAction } from 'mobx';
import { DeliveryHistory, IDeliveryHistoryServices } from 'services/deliveryHistory_services';

export type DeliveryHistoryStore = {
    // search response// dữ liệu tìm kiếm delivery history
    listDeliveryHistory: {
        message?: string;
        data: Array<DeliveryHistory>;
        draw: number;
        recordsTotal: number;
        recordsFiltered: number;
    };
};

export interface IDeliveryHistoryPresenter {
    createStore(): DeliveryHistoryStore;
    searchDeliveryHistory(store: DeliveryHistoryStore, data: object): Promise<boolean>;
}

export function createDeliveryHistoryPresenter({
    deliveryHistoryServices,
}: {
    deliveryHistoryServices: IDeliveryHistoryServices;
}): IDeliveryHistoryPresenter {
    return {
        createStore: (): DeliveryHistoryStore =>
            observable({
                listDeliveryHistory: {
                    message: '',
                    data: [],
                    draw: 1,
                    recordsTotal: 0,
                    recordsFiltered: 0,
                },
            }),
        searchDeliveryHistory: async (store: DeliveryHistoryStore, data: object) => {
            try {
                const result = await deliveryHistoryServices.searchDeliveryHistory(data);
                runInAction(() => {
                    store.listDeliveryHistory.data = result.data;
                    store.listDeliveryHistory.message = result.message;
                    store.listDeliveryHistory.draw = result.draw;
                    store.listDeliveryHistory.recordsTotal = result.recordsTotal;
                    store.listDeliveryHistory.recordsFiltered = result.recordsFiltered;
                });
                if (result) {
                    return true;
                }
                return false;
            } catch (e) {
                return false;
            }
        },
    };
}
