import { post } from 'utils/api';

// model lịch sử giao hàng
export type DeliveryHistory = {
    stt: number;
    rawMaterialCardId: string;
    createTime: Date;
    purchaseOrderCode: string;
    sosoLine: any[];
    wbs: any[];
    plant?: any;
    documentDate: Date;
    documentDateStr: string;
    productCode: string;
    productName: string;
    poQuantity: number;
    poQuantity_Delivered: number;
    quantity?: any;
    poQuantityUnit: string;
    vendorCode: string;
    vendorName: string;
    isReceive: boolean;
    receiveDate?: any;
    actived: boolean;
};
// reponse api trả về có phân trang
export type DeliveryHistoryResponse = {
    code: number;
    message?: any;
    data: DeliveryHistory[];
    draw: number;
    recordsTotal: number;
    recordsFiltered: number;
};

export interface IDeliveryHistoryServices {
    // tìm kiếm lịch sử giao hàng (có phân trang)
    // nếu data truyền vô chỉ có paging thì lấy tất cả
    searchDeliveryHistory(data: any): Promise<DeliveryHistoryResponse>;
}

export function createDeliveryHistoryServices(): IDeliveryHistoryServices {
    return {
        searchDeliveryHistory: async (data: any): Promise<DeliveryHistoryResponse> => {
            return (await post(`MES/DeliveryHistory/Search`, data)).data;
        },
    };
}
