import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ROUTES } from 'base/routes/routes';

import { observer } from 'mobx-react';
import * as rootStore from 'stores/root_store';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { createLoginLayout, createDefaultLayout } from 'base/ui/layout/layout';
import { createPages } from 'pages/create';
import { Header as HeaderView } from 'base/ui/layout/header/header';
import { Sidebar as SidebarView } from 'base/ui/layout/sideBar/sideBar';
import { Footer as FooterView } from 'base/ui/layout/footer/footer';

export function createApp() {
    // const services = createServices('api-url');
    const services = rootStore.services;
    // const userPresenter = createUserPresenter(services);

    // Create global stores
    // const userStore = userPresenter.createStore();
    // const userStore = rootStore.userStore;
    // Init/load stores

    // khởi tạo header, sidebar, footer
    const Header = observer(() => <HeaderView />);
    const Sidebar = observer(() => <SidebarView />);
    const Footer = observer(() => <FooterView />);

    // Tạo ra 2 layout
    // Layout cho trang login chỉ có content không có sidebar, header, footer
    const layoutLogin = createLoginLayout();
    // Layout mặc định sẽ có đầy đủ sidebar, header, footer
    const layoutDefault = createDefaultLayout(Header, Sidebar, Footer);
    // khỏi tạo các trang với layout tương ứng
    const { LoginPage } = createPages(layoutLogin, services);
    const {
        HomePage,
        PurchaseOrderPage,
        PODetailsPage,
        PrintBarcodePage,
        BarcodeHistoryPage,
        DeliveryHistoryDetailPage,
        ChangePasswordPage,
    } = createPages(layoutDefault, services);
    return () => (
        <>
            <BrowserRouter>
                <Routes>
                    {/* Trang đăng nhập */}
                    <Route path={ROUTES.LOGIN.BASE} element={<LoginPage />} />
                    {/* Trang chủ */}
                    <Route path={ROUTES.HOME} element={<HomePage />} />
                    {/* Trang Purchase Order */}
                    <Route path={ROUTES.PURCHASE_ORDER.BASE} element={<PurchaseOrderPage />} />
                    {/* Trang Purchase Order Detail */}
                    <Route path={ROUTES.PURCHASE_ORDER_DETAIL.BASE} element={<PODetailsPage />} />
                    {/* Trang in barcode */}
                    <Route path={ROUTES.PRINT_BARCODE.BASE} element={<PrintBarcodePage />} />
                    {/* Trang lịch sử giao hàng */}
                    <Route path={ROUTES.HISTORY.BASE} element={<BarcodeHistoryPage />} />
                    {/* Chi tiết lịch sử giao hàng */}
                    <Route path={ROUTES.HISTORY_DETAIL.BASE} element={<DeliveryHistoryDetailPage />} />
                    {/* Trang đổi mật khẩu */}
                    <Route path={ROUTES.CHANGE_PASSWORD.BASE} element={<ChangePasswordPage />} />
                </Routes>
            </BrowserRouter>
            {/* React toastify // hiển thị thông báo */}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}
