import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as rootStore from 'stores/root_store';

import { FaUser } from 'react-icons/fa';
import { HiChevronDown } from 'react-icons/hi';
import { FiLogOut } from 'react-icons/fi';
import { TbKey } from 'react-icons/tb';

export const Header = React.memo(() => {
    const navigate = useNavigate();
    // Yêu cầu: click vô avata show popup , click ra ngoài thì ẩn đi
    // sử dụng một state để lưu trang thái open hay không open
    const [open, setOpen] = useState(false);
    const popupRef = useRef<any>(null);
    const data = JSON.parse(localStorage.getItem('user_session') || '{}');

    // theo dỗi sự thay đổi của ref
    // thêm sự kiện mousedown trên popup nếu click ra ngoài thì setOpen = false
    useEffect(() => {
        const handleClickAway = (e: any) => {
            if (popupRef.current && !popupRef.current.contains(e.target)) {
                setOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickAway);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickAway);
        };
    }, [popupRef]);

    const handleShow = () => {
        setOpen(!open);
    };

    const handleSignOut = () => {
        rootStore.userPresenter.signOut();
        window.location.reload();
    };

    return (
        <div className="min-h-[50px] w-full bg-primary shadow-md shadow-gray-400 px-5 flex items-center justify-end">
            <div className="relative">
                <div className="flex items-center">
                    <div className="max-h-[30px] rounded-sm text-default font-medium bg-gray-700 text-white px-3 py-1 mr-5 hidden sm:block">
                        <span>{data.role}</span>
                    </div>
                    <div onClick={handleShow} className="h-[50px] flex items-center text-white cursor-pointer">
                        <span className="w-6 h-6 rounded-full bg-gray-200 border-2 border-white flex items-center justify-center">
                            <FaUser className="text-gray-500 text-default" />
                        </span>
                        <span className="text-xs font-medium text-white mx-3 select-none">
                            {data.fullName}({data.userName})
                        </span>
                        <HiChevronDown />
                    </div>
                </div>
                <div
                    ref={popupRef}
                    className={`absolute bg-white shadow-lg right-0 w-max h-max py-2 rounded cursor-pointer text-gray-600 ${
                        open ? '' : 'hidden'
                    }`}
                >
                    <span
                        onClick={() => navigate('/Permission/Auth/ChangePassword')}
                        className="flex py-1 text-default px-5 hover:bg-gray-200 items-center border-b"
                    >
                        <TbKey className="mr-3" />
                        Đổi mật khẩu
                    </span>
                    <span
                        onClick={handleSignOut}
                        className="flex py-1 text-default px-5 hover:bg-gray-200 items-center"
                    >
                        <FiLogOut className="mr-3" />
                        Đăng xuất
                    </span>
                </div>
            </div>
        </div>
    );
});
