import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';
import ReactPaginate from 'react-paginate';

type Props = {
    pageCount: number;
    onPageChange: any;
    forcePage: number;
    onPageSizeChange: any;
};

function Pagination(props: Props) {
    // lựa chọn page size
    const pageSizes = [10, 25, 50, 100];
    return (
        <div className="w-full flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row justify-between">
            <div className="text-sm font-medium mb-1">
                <span className="">Xem</span>
                <select
                    onChange={props.onPageSizeChange}
                    className="min-w-[4rem] border mx-1.5 outline-none p-1.5 text-gray-600"
                >
                    {pageSizes.map((item) => (
                        <option key={item} value={item}>
                            {item}
                        </option>
                    ))}
                </select>
                <span>mục</span>
            </div>
            {/* Phân trang bằng react paginate */}
            <ReactPaginate
                previousLabel={<HiChevronDoubleLeft />}
                breakLabel="..."
                nextLabel={<HiChevronDoubleRight />}
                onPageChange={props.onPageChange}
                forcePage={props.forcePage}
                pageRangeDisplayed={2}
                marginPagesDisplayed={2}
                pageCount={props.pageCount}
                containerClassName="flex"
                pageClassName="text-xs text-gray-500 block min-w-[32px] h-8 bg-gray-50 flex justify-center items-center border border-r-0  border-gray-300"
                previousClassName="text-xs text-gray-500 block min-w-[32px] h-8 bg-gray-50 flex justify-center items-center rounded-l border border-r-0 border-gray-300"
                nextClassName="text-xs text-gray-500 block min-w-[32px] h-8 bg-gray-50 flex justify-center items-center rounded-r border border-gray-300"
                breakClassName="text-xs text-gray-500 block min-w-[32px] h-8 bg-gray-50 flex justify-center items-center border border-r-0  border-gray-300"
                activeClassName="!bg-primary border-blue-600 !text-white"
            />
        </div>
    );
}

export default Pagination;
