import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import * as rootStore from "stores/root_store";

import { IoArrowBackCircle } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "base/ui/components/Pagination/pagination";
import { PAGE_SIZE_DEFAULT } from "utils/constants";
import { TbLoaderQuarter } from "react-icons/tb";
import { HiSearch } from "react-icons/hi";
import { PurchaseOrderDetail } from "services/purchaseOrder_services";
import LoadingScreen from "base/ui/components/LoadingScreen/loadingScreen";
import { Backdrop, CircularProgress } from "@mui/material";

export function createPurchaseOrderDetail() {
  return observer(() => {
    document.title = "Purchase Order detail- iMES WebPortal";
    const navigate = useNavigate();
    // phân trang
    // số lượng items trên 1 trang
    const [pageSize, setPageSize] = useState(PAGE_SIZE_DEFAULT);
    // tổng số trang
    const [pageCount, setPageCount] = useState(0);
    // bắt đầu từ item (start)
    const [currentPage, setcurrentPage] = useState(0);
    // trang được select // trang hiện tại
    // mặc định trang là trang đầu tiên (trang 1 select = 0)
    // set -1 để không bị báo lỗi: (react-paginate): The forcePage prop provided is greater than the maximum page index from pageCount prop (0 > -1).
    const [pageSelected, setPageSelected] = useState(-1);
    // xem một lúc nhiều po
    const [checkedPO, setCheckedPO] = useState<Array<string>>([]);
    const [loading, setLoading] = useState(true);
    const [material, setMaterial] = useState("");
    // chọn một PO để xem chi tiết
    const column = [
      {
        columnName: "Chức năng",
        width: 180,
        align: "text-center",
        visible: true,
      },
      { columnName: "STT", width: 90, align: "center", visible: true },
      {
        columnName: "Số PO",
        width: 180,
        align: "text-center",
        visible: true,
      },
      {
        columnName: "Dòng",
        width: 180,
        align: "text-center",
        visible: true,
      },
      {
        columnName: "Nhà máy",
        width: 180,
        align: "text-center",
        visible: true,
      },
      {
        columnName: "Mã material",
        width: 180,
        align: "text-center",
        visible: true,
      },
      {
        columnName: "Deletion ind",
        width: 180,
        align: "text-center",
        visible: false,
      },
      {
        columnName: "Tên hàng hóa",
        width: 180,
        align: "text-center",
        visible: true,
      },
      {
        columnName: "Kho",
        width: 180,
        align: "text-center",
        visible: true,
      },
      {
        columnName: "Số lượng đặt hàng",
        width: 180,
        align: "text-center",
        visible: true,
      },
      {
        columnName: "Số lượng nhập kho lũy kế",
        width: 180,
        align: "text-center",
        visible: true,
      },
      {
        columnName: "Đơn vị tính",
        width: 180,
        align: "text-center",
        visible: true,
      },
      {
        columnName: "Deliv. Compl",
        width: 180,
        align: "text-center",
        visible: false,
      },
      {
        columnName: "Acct Assgmt Cat",
        width: 180,
        align: "text-center",
        visible: false,
      },
      {
        columnName: "Item category",
        width: 180,
        align: "text-center",
        visible: false,
      },
      {
        columnName: "Goods receipt",
        width: 180,
        align: "text-center",
        visible: false,
      },
      { columnName: "SO", width: 180, align: "text-center", visible: true },
      {
        columnName: "SO Line",
        width: 180,
        align: "text-center",
        visible: true,
      },
      { columnName: "Item", width: 180, align: "text-center", visible: false },
      { columnName: "WBS", width: 180, align: "text-center", visible: true },

      // { columnName: 'Số lượng còn lại', width: 180, align: 'text-center', visible: true },
    ];
    // lấy mảng po id từ localStorage
    const dataPO = JSON.parse(localStorage.getItem("po_ids") || "{}");
    // dữ liệu các cột của bảng detail
    const [tableRows, setTableRows] = useState<PurchaseOrderDetail[]>();

    const [isCheckAll, setCheckAll] = useState(false);

    const handleUncheckedAll = () => {
      // Assuming `checked` is a state variable that contains the checked rows
      setCheckedPO([]);

      var checkboxAll = document.querySelector("#checkAll") as HTMLInputElement;
      checkboxAll.checked = false;
    };

    useEffect(() => {
      const checkboxes = document.querySelectorAll(".row-checkbox");
      checkboxes.forEach((checkbox) => {
        var theCheckbox = checkbox as HTMLInputElement;
        theCheckbox.checked = isCheckAll;
      });
    }, [isCheckAll]);

    // call api lấy details
    useEffect(() => {
      const paginate = async () => {
        setLoading(true);
        await rootStore.purchaseOrderPresenter.getPODetail(
          rootStore.purchaseOrderStore,
          {
            purchaseOrderIds: dataPO,
            paging: {
              draw: 1,
              start: currentPage,
              length: pageSize,
            },
          }
        );
        setTableRows(rootStore.purchaseOrderStore.purchaseOrderDetail.data);
        setLoading(false);
      };
      paginate();
    }, [currentPage]);

    //xử lý thay đổi page size
    useEffect(() => {
      // call api thay đổi số item trên 1 trang
      const pagination = async () => {
        // cho loading
        setLoading(true);
        const result = await rootStore.purchaseOrderPresenter.getPODetail(
          rootStore.purchaseOrderStore,
          {
            purchaseOrderIds: dataPO,
            paging: {
              draw: 1,
              start: currentPage,
              length: pageSize,
            },
          }
        );

        if (result) {
          // số trang bằng tổng số item chia số item trên 1 trang :pageCount = recordsFiltered / pageSize (recordsFiltered lưu trong store =>lấy từ api)
          setPageCount(
            Math.ceil(
              rootStore.purchaseOrderStore.purchaseOrderDetail.recordsFiltered /
                pageSize
            )
          );
          // setLoading(false);
        }
        // nếu page size thay đổi thì chuyển về trang đầu tiên
        setPageSelected(0);
        setcurrentPage(0);
        setTableRows(rootStore.purchaseOrderStore.purchaseOrderDetail.data);
        setLoading(false);
      };
      pagination();
    }, [pageSize]);

    // sự kiện lưu trạng thái checkbox và id po detail
    const handleChecked = (id: string) => {
      setCheckedPO((prev) => {
        const isChecked = checkedPO.includes(id);
        if (isChecked) {
          return checkedPO.filter((item) => item !== id);
        } else {
          return [...prev, id];
        }
      });
    };

    // in barcode 1 hoặc nhiều po details
    // check nhiều po và chọn xem chi tiết
    const handlePrintBarcode = async (id?: string) => {
      // nếu có id là in 1 po details
      if (id) {
        const selectPO = [id];
        await rootStore.barcodePrensenter.getListItemPO(
          rootStore.barcodeStore,
          selectPO
        );
        if (rootStore.barcodeStore.listItemPO?.isSuccess) {
          localStorage.setItem("listitem_PO", JSON.stringify(selectPO) || "{}");
          navigate("./../printBarCode");
        } else {
          toast.error(rootStore.barcodeStore.listItemPO?.message);
        }
        // ngược lại thì in nhiều
      } else {
        await rootStore.barcodePrensenter.getListItemPO(
          rootStore.barcodeStore,
          checkedPO
        );
        if (rootStore.barcodeStore.listItemPO?.isSuccess) {
          localStorage.setItem(
            "listitem_PO",
            JSON.stringify(checkedPO) || "{}"
          );
          navigate("./../printBarCode");
        } else {
          toast.error(rootStore.barcodeStore.listItemPO?.message);
        }
      }
    };

    // bắt sự kiện chuyển trang
    const handlePageClick = (data: any) => {
      setPageSelected(data.value);
      // lấy từ item số n (start)
      // trang 1 bắt đầu từ item thứ 0 ,
      // trang 2 bắt đầu từ item thứ (số trang -1) * (số item trên trang + 1), vd pageSize = 10 => currentPage = (2-1)*(10+1)=11
      // data.selected để lấy ra số trang
      // vd trang = 1 thì data.selected = 0
      setcurrentPage(data.selected * pageSize);
    };
    // lấy value của select để set page size (thay đổi số lượng item trên trang)
    const handleSelectPageSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
      setPageSize(parseInt(e.target.value));
    };

    // lấy value trên input và set state
    const handleChangeMaterial = (e: React.ChangeEvent<HTMLInputElement>) => {
      const search = e.target.value;
      setTableRows(
        rootStore.purchaseOrderStore.purchaseOrderDetail.data.filter((row) => {
          return row.materialCode.includes(search);
        })
      );
    };

    return (
      <div className="w-full p-5 py-3">
        {/* nút back */}
        <span
          onClick={() => navigate("./../")}
          className="flex items-center text-xs text-primary cursor-pointer mb-3 font-medium"
        >
          <IoArrowBackCircle className="mr-2 text-base text-gray-500" />
          Quay lại
        </span>
        {/* title -  */}
        <div className="w-full px-5 py-2 bg-white rounded flex items-center rounded-b-none border border-t-2 border-t-sky-700 border-neutral-300">
          <h3 className="text-md font-bold text-gray-800 uppercase">
            Purchase Order Detail
          </h3>
        </div>
        <ToastContainer />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <div className="w-full bg-white py-3 px-5 rounded rounded-t-none border border-t-0 border-neutral-300 mb-2">
          <form className="w-full py-2 ">
            <div className="w-full flex flex-col sm:flex-row lg:px-20 lg:pl-3 mb-3 sm:items-center">
              <label
                htmlFor="materialSearch"
                className="min-w-[150px] font-medium text-default text-gray-700 sm:text-end"
              >
                Mã material
              </label>
              <input
                id="materialSearch"
                onChange={handleChangeMaterial}
                className="text-gray-700 border border-gray-300 sm:ml-5 px-3 py-1.5 text-default flex-1 focus-visible:outline-none focus:border-blue-500 caret-blue-600"
              />
            </div>
          </form>
        </div>
        {/* ============ Table danh sách PO detail ====================*/}

        <div className="w-full bg-white py-3 px-5 rounded border border-t-0 border-neutral-300">
          {/*=========== Pagination =======================*/}
          <Pagination
            pageCount={pageCount}
            forcePage={pageSelected}
            onPageChange={handlePageClick}
            onPageSizeChange={handleSelectPageSize}
          />
          <div className="flex justify-start sm:justify-end mt-1">
            <button
              type="button"
              onClick={() => handlePrintBarcode()}
              className="flex h-fit items-center text-white bg-blue-700 rounded text-default px-2.5 py-1 text-center focus:outline-none hover:bg-blue-600"
            >
              Tạo barcode
            </button>
          </div>
          {/* =========== Table PO ===============*/}
          <div className={`overflow-x-auto mt-3 `}>
            <table className="w-full table-auto border-collapse border space-collapse mb-5">
              <thead className="text-default text-gray-700 bg-gray-100 border-2">
                <tr>
                  {column.map((item, index) => {
                    if (item.visible) {
                      return (
                        <th
                          key={index}
                          scope="col"
                          className={`${
                            item.visible ? "" : "hidden"
                          } py-2 px-2 border whitespace-nowrap text-left ${
                            item.align
                          }`}
                        >
                          {item.columnName}
                          {item.columnName === "Chức năng" && (
                            <div>
                              <input
                                type="checkbox"
                                id="checkAll"
                                onChange={(e) => {
                                  setCheckAll(e.target.checked);
                                  if (e.target.checked) {
                                    tableRows?.forEach((row) =>
                                      handleChecked(row.poDetailId)
                                    );
                                  } else {
                                    handleUncheckedAll();
                                  }
                                }}
                              />
                            </div>
                          )}
                        </th>
                      );
                    }
                  })}
                </tr>
              </thead>
              <tbody>
                {tableRows?.length ? (
                  tableRows.filter((item) => item.deliveryCompleted !== "X")
                    .length ? (
                    tableRows
                      .filter((item) => item.deliveryCompleted !== "X")
                      .map((row, index) => (
                        <tr
                          key={index}
                          className={`${
                            index % 2 === 0 ? "bg-gray-50" : "bg-white"
                          } border-b`}
                        >
                          <td className="py-1.5 px-2 border text-default whitespace-nowrap">
                            <input
                              type="checkbox"
                              checked={checkedPO.includes(row.poDetailId)}
                              onChange={() => handleChecked(row.poDetailId)}
                              value={row.poDetailId}
                              className="block m-auto"
                            />
                          </td>
                          <td className="py-1.5 px-2 border text-default whitespace-nowrap text-center">
                            {row.stt}
                          </td>
                          <td
                            onClick={() => handlePrintBarcode(row.poDetailId)}
                            className="py-1.5 px-2 border text-default whitespace-nowrap cursor-pointer text-sky-700 hover:text-sky-600 text-center"
                          >
                            {row.poCode}
                          </td>
                          <td className="py-1.5 px-2 border text-default whitespace-nowrap text-center">
                            {row.poItem}
                          </td>
                          <td className="py-1.5 px-2 border text-default whitespace-nowrap text-center">
                            {row.plant}
                          </td>
                          <td className="py-1.5 px-2 border text-default whitespace-nowrap text-center">
                            {row.materialCode}
                          </td>
                          <td className="py-1.5 px-2 border text-default whitespace-nowrap">
                            {row.productName}
                          </td>
                          <td className="py-1.5 px-2 border text-default whitespace-nowrap">
                            {row.storageLocation}
                          </td>
                          {/* Số lượngđặt hàng */}
                          <td className="py-1.5 px-2 border text-default whitespace-nowrap text-right">
                            {row.poQuantity
                              .toFixed(3)
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                          </td>
                          {/* Số lượng nhập kho */}
                          <td className="py-1.5 px-2 border text-default whitespace-nowrap text-right">
                            {parseFloat(row.cummulativeQuantity)
                              .toFixed(3)
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                          </td>
                          <td className="py-1.5 px-2 border text-default whitespace-nowrap">
                            {row.orderUnit}
                          </td>
                          <td className="py-1.5 px-2 border text-default whitespace-nowrap">
                            {row.sdDocument}
                          </td>
                          <td className="py-1.5 px-2 border text-default whitespace-nowrap">
                            {row.soLine}
                          </td>
                          <td className="py-1.5 px-2 border text-default whitespace-nowrap">
                            {row.wbs}
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td
                        colSpan={column.length}
                        className="py-1.5 px-2 text-default border whitespace-nowrap text-center"
                      >
                        Không tìm thấy dòng nào phù hợp
                      </td>
                    </tr>
                  )
                ) : (
                  <tr>
                    <td
                      colSpan={column.length}
                      className="py-1.5 px-2 text-default border whitespace-nowrap text-center"
                    >
                      Không tìm thấy dòng nào phù hợp
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  });
}
