import { LayoutHOC } from 'base/ui/layout/layout';
import { IPurchaseOrderServices } from 'services/purchaseOrder_services';
import { createPrintBarcode } from './printBarCode';

export const createPrintBarcodePage = (
    layoutHOC: LayoutHOC,
    services: { purchaseOrderServices: IPurchaseOrderServices },
) => {
    return layoutHOC(createPrintBarcode(services));
};
