import { observable, runInAction } from "mobx";
import {
  IPurchaseOrderServices,
  PurchaseOrder,
  PurchaseOrderDetail,
  Vendor,
  Common,
} from "services/purchaseOrder_services";

export type PurchaseOrderStore = {
  dataSearchPurchaseOrder: {
    data: Array<PurchaseOrder>;
    draw: number;
    recordsTotal: number;
    recordsFiltered: number;
  };
  purchaseOrderDetail: {
    isSuccess: boolean;
    message: string;
    data: Array<PurchaseOrderDetail>;
    draw: number;
    recordsTotal: number;
    recordsFiltered: number;
  };
  listVendor: {
    isSuccess: boolean;
    message: string;
    data: Array<Vendor>;
  };
  approveViewVendorResponse: {
    isSuccess: boolean;
    message: string;
  };
  commonProduct: {
    isSuccess: boolean;
    message: string;
    data: Common[];
  };
  commonPOCode: {
    isSuccess: boolean;
    message: string;
    data: Common[];
  };
};

export interface IPurchaseOrderPresenter {
  createStore(): PurchaseOrderStore;
  searchPurchaseOrder(
    store: PurchaseOrderStore,
    data: object
  ): Promise<boolean>;
  getPODetail(store: PurchaseOrderStore, data: object): Promise<boolean>;
  searchVendor(store: PurchaseOrderStore, data: string): Promise<void>;
  approveViewVendor(store: PurchaseOrderStore, data: object): Promise<void>;
  getProduct(store: PurchaseOrderStore, data: string): Promise<void>;
  getDropDownPo(store: PurchaseOrderStore, data: string): Promise<void>;
}

export function createPurchaseOrderPresenter({
  purchaseOrderServices,
}: {
  purchaseOrderServices: IPurchaseOrderServices;
}): IPurchaseOrderPresenter {
  return {
    createStore: (): PurchaseOrderStore =>
      observable({
        dataSearchPurchaseOrder: {
          data: [],
          draw: 1,
          recordsTotal: 0,
          recordsFiltered: 0,
        },
        purchaseOrderDetail: {
          data: [],
          draw: 1,
          recordsTotal: 0,
          recordsFiltered: 0,
          message: "",
          isSuccess: false,
        },
        listVendor: {
          isSuccess: false,
          message: "",
          data: [],
        },
        approveViewVendorResponse: {
          isSuccess: false,
          message: "",
        },
        commonProduct: {
          isSuccess: false,
          message: "",
          data: [],
        },
        commonPOCode: {
          isSuccess: false,
          message: "",
          data: [],
        },
      }),

    searchPurchaseOrder: async (store: PurchaseOrderStore, data: object) => {
      try {
        const result = await purchaseOrderServices.geListtPurchaseOrder(data);

        runInAction(() => {
          store.dataSearchPurchaseOrder.data = result.data;
          store.dataSearchPurchaseOrder.draw = result.draw;
          store.dataSearchPurchaseOrder.recordsTotal = result.recordsTotal;
          store.dataSearchPurchaseOrder.recordsFiltered =
            result.recordsFiltered;
        });
        if (result) {
          return true;
        } else return false;
      } catch (error) {
        return false;
      }
    },
    getPODetail: async (store: PurchaseOrderStore, data: object) => {
      try {
        const result = await purchaseOrderServices.getDetailPurchaseOrder(data);
        runInAction(() => {
          store.purchaseOrderDetail.data = result.data;
          store.purchaseOrderDetail.draw = result.draw;
          store.purchaseOrderDetail.recordsTotal = result.recordsTotal;
          store.purchaseOrderDetail.recordsFiltered = result.recordsFiltered;
          store.purchaseOrderDetail.message = result.message;
          store.purchaseOrderDetail.isSuccess = result.isSuccess;
        });
        if (result) {
          return true;
        } else return false;
      } catch (error) {
        return false;
      }
    },
    searchVendor: async (store: PurchaseOrderStore, data: string) => {
      try {
        const result = await purchaseOrderServices.searchVendor(data);
        runInAction(() => {
          store.listVendor.data = result.data;
          store.listVendor.isSuccess = result.isSuccess;
          store.listVendor.message = result.message;
        });
      } catch (error) {}
    },
    approveViewVendor: async (store: PurchaseOrderStore, data: object) => {
      try {
        const result = await purchaseOrderServices.approveViewVendor(data);
        runInAction(() => {
          store.approveViewVendorResponse.isSuccess = result.isSuccess;
          store.approveViewVendorResponse.message = result.message;
        });
      } catch (error) {}
    },
    getProduct: async (
      store: PurchaseOrderStore,
      data: string
    ): Promise<void> => {
      try {
        const result = await purchaseOrderServices.getProduct(data);
        runInAction(() => {
          store.commonProduct.data = result.data;
          store.commonProduct.message = result.message;
          store.commonProduct.isSuccess = result.isSuccess;
        });
      } catch (error) {
        console.log(error);
      }
    },
    getDropDownPo: async (store: PurchaseOrderStore, data: string) => {
      try {
        const result = await purchaseOrderServices.GetDropDownPO(data);
        runInAction(() => {
          store.commonPOCode.data = result.data;
          store.commonPOCode.message = result.message;
          store.commonPOCode.isSuccess = result.isSuccess;
        });
      } catch (error) {
        console.log(error);
      }
    },
  };
}
