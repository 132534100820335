import { post, get } from 'utils/api';

// Models
// danh sách PO có phân trang
// Search PO

export type searchPurchaseOrder = {
    data: PurchaseOrder[];
    draw: number;
    recordsTotal: number;
    recordsFiltered: number;
};

export type PurchaseOrder = {
    stt: number;
    purchaseOrderId: string;
    purchaseOrderCode: string;
    companyCode: string;
    documentType: string;
    deletionInd: string;
    vendorNumber: string;
    vendorName: string;
    purchasingOrg: string;
    documentDate: string;
    productCodeName: string[];
    actived: boolean;
    isVendorView: boolean;
};

// PO details có phân trang
export type PODetail = {
    data: PurchaseOrderDetail[];
    draw: number;
    recordsTotal: number;
    recordsFiltered: number;
    message: string;
    isSuccess: boolean;
};

export type PurchaseOrderDetail = {
    stt: number;
    poDetailId: string;
    poCode: string;
    poItem: string;
    materialCode: string;
    productName: string;
    storageLocation: string;
    poQuantity: number;
    orderUnit: string;
    sdDocument: string;
    soLine: string;
    wbs: string;
    cummulativeQuantity: string;
    deliveryCompleted?: string;
    plant: string;
};

// List vendor module
export type Vendor = {
    vendorCode: string;
    vendorDisplay: string;
};

export type VendorRespone = {
    isSuccess: boolean;
    message: string;
    data: Vendor[];
};

// =========== Duyệt PurchaseOrder module ==========
export type ApprovPurchaseOrder = {
    isSuccess: boolean;
    message: string;
};
// ================================================//

// ================= Lấy danh sách theo search (tên và mã) (material) ==============//
export type Common = {
    key: string;
    value: string;
};
export type CommonResponse = {
    isSuccess: boolean;
    data: Common[];
    message: string;
};

export interface IPurchaseOrderServices {
    geListtPurchaseOrder(data: any): Promise<searchPurchaseOrder>;
    getDetailPurchaseOrder(data: any): Promise<PODetail>;
    searchVendor(data: string): Promise<VendorRespone>;
    approveViewVendor(data: any): Promise<ApprovPurchaseOrder>;
    getProduct(data: string): Promise<CommonResponse>;
    GetDropDownPO(value: string): Promise<CommonResponse>;
}

export function createPurchaseOrderServices(url: string): IPurchaseOrderServices {
    return {
        // Lấy danh sách purchaseOrder
        // Nếu chỉ truyền (draw, lenght, start) -> thì lấy tất cả theo phân trang
        // Nếu có thêm tham số khác sẽ search theo tham số
        geListtPurchaseOrder: async (data: any): Promise<searchPurchaseOrder> => {
            return (await post(`MES/PurchaseOrder/Search`, data)).data;
        },
        getDetailPurchaseOrder: async (data: any): Promise<PODetail> => {
            return (await post(`MES/PurchaseOrder/GetDetail`, data)).data;
        },
        searchVendor: async (data: string): Promise<VendorRespone> => {
            return (await get(`MES/PurchaseOrder/ListVendor?VendorCode=${data}`)).data;
        },
        approveViewVendor: async (data: any): Promise<ApprovPurchaseOrder> => {
            return (await post(`MES/PurchaseOrder/ApproveViewVendor`, data)).data;
        },
        getProduct: async (data: string): Promise<CommonResponse> => {
            return (await get(`MES/Common/GetProduct?ProductCode=${data}`)).data;
        },
        GetDropDownPO: async (value: string): Promise<CommonResponse> => {
            return (await get(`MES/Common/GetDropDownPurchaseOrder?kw=${value}`)).data;
        },
    };
}
