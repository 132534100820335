import { createServices } from 'services/services';
import { createUserPresenter } from 'stores/user_store';
import { createBarcodePresenter } from './barcode_store';
import { createPurchaseOrderPresenter } from './purchaseOrder_store';
import { createCompanypresenter } from './company_store';
import { createDeliveryHistoryPresenter } from './deliveryHistory_store';

// khỏi tại services
export const services = createServices('');

// khởi tạo presenter và store của user
export const userPresenter = createUserPresenter(services);
export const userStore = userPresenter.createStore();

// khởi tạo presenter và store của barcode
export const barcodePrensenter = createBarcodePresenter(services);
export const barcodeStore = barcodePrensenter.createStore();

// khởi tạo presenter và store của purchase order
export const purchaseOrderPresenter = createPurchaseOrderPresenter(services);
export const purchaseOrderStore = purchaseOrderPresenter.createStore();

// khởi tạo presenter và store của company
export const companyPresenter = createCompanypresenter(services);
export const companyStore = companyPresenter.createStore();

// khởi tạo deliveryHistory store và presenter
export const deliveryHistoryPresenter = createDeliveryHistoryPresenter(services);
export const deliveryHistoryStore = deliveryHistoryPresenter.createStore();
