import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import { BiSave } from 'react-icons/bi';
import { useForm } from 'react-hook-form';
import { userPresenter, userStore } from 'stores/root_store';

interface ChangePasswordModel {
    oldPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
}

const schema = yup.object().shape({
    oldPassword: yup.string(),
    newPassword: yup.string(),
    newPasswordConfirm: yup.string().oneOf([yup.ref('newPassword'), null], 'Mật khẩu và Mật khẩu xác nhận không khớp!'),
});

export function createChangePassword() {
    return observer(() => {
        document.title = 'Purchase Order - iMES WebPortal';
        const navigate = useNavigate();
        const userName = JSON.parse(localStorage.getItem('user_session') || '{}').userName;

        // ==================Validate =====================================
        const {
            register,
            handleSubmit,
            formState: { errors },
        } = useForm<ChangePasswordModel>({
            resolver: yupResolver(schema),
        });
        //================================================================

        const onSubmit = async (vale: ChangePasswordModel) => {
            await userPresenter.changePassword(userStore, vale);
            if (userStore.changePasswordResponse.isSuccess) {
                toast.success(userStore.changePasswordResponse.message);
                if (window.confirm(userStore.changePasswordResponse.message + '\n Bạn có muốn đăng nhập lại?')) {
                    userPresenter.signOut();
                    window.location.reload();
                } else {
                    navigate('');
                }
            } else {
                toast.error(userStore.changePasswordResponse.message);
            }
        };
        return (
            <div className="w-full py-3 px-5">
                {/* Form nhập tiềm kiếm */}
                <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                    <div className="flex items-center justify-between">
                        <h3 className="text-md font-bold text-gray-800 mb-2 uppercase">Danh sách PO</h3>
                        <button
                            type="submit"
                            className="flex items-center text-white bg-blue-700 rounded text-sm mb-1.5 px-2.5 py-1 text-center focus:outline-none hover:bg-blue-600"
                        >
                            <BiSave className="text-base" />
                            Lưu
                        </button>
                    </div>
                    <div className="w-full bg-white py-3 px-5 rounded mb-2 border border-neutral-300">
                        <div className="w-full md:flex items-center mb-3">
                            <label
                                htmlFor="userName"
                                className="block min-w-[350px] md:text-end pr-3 font-medium text-sm text-gray-700 text-start"
                            >
                                Tên tài khoản
                            </label>
                            <input
                                disabled
                                id="userName"
                                value={userName}
                                className="w-full text-gray-700 border border-gray-300 px-3 py-1.5 text-sm flex-1 focus-visible:outline-none focus:border-blue-500 caret-blue-600 disabled:bg-gray-200"
                            />
                        </div>
                        <div className="w-full md:flex items-center mb-3">
                            <label
                                htmlFor="oldPassword"
                                className="block min-w-[350px] md:text-end pr-3 font-medium text-sm text-gray-700 text-start"
                            >
                                Mật khẩu cũ
                            </label>
                            <input
                                {...register('oldPassword')}
                                type="password"
                                required
                                id="oldPassword"
                                className="w-full text-gray-700 border border-gray-300 px-3 py-1.5 text-sm flex-1 focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                            />
                        </div>
                        <div className="w-full md:flex items-center mb-3">
                            <label
                                htmlFor="newPassword"
                                className="block min-w-[350px] md:text-end pr-3 font-medium text-sm text-gray-700 text-start"
                            >
                                Mật khẩu mới
                            </label>
                            <input
                                {...register('newPassword')}
                                required
                                type="password"
                                id="newPassword"
                                className="w-full text-gray-700 border border-gray-300 px-3 py-1.5 text-sm flex-1 focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                            />
                        </div>
                        <div className="w-full md:flex items-center mb-3">
                            <label
                                htmlFor="confirmPassword"
                                className="block min-w-[350px] md:text-end pr-3 font-medium text-sm text-gray-700 text-start"
                            >
                                Xác nhận mật khẩu mới
                            </label>
                            <input
                                {...register('newPasswordConfirm')}
                                type="password"
                                required
                                id="passwordConfirmation"
                                className="w-full text-gray-700 border border-gray-300 px-3 py-1.5 text-sm flex-1 focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                            />
                        </div>
                        <div className="w-full md:flex items-center mb-3">
                            <label className="block min-w-[350px] md:text-end pr-3 font-medium text-sm text-gray-700 text-start"></label>
                            {errors.newPasswordConfirm && (
                                <p className="text-xs text-red-500">{`${errors.newPasswordConfirm.message}`}</p>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        );
    });
}
