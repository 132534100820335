import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  SyntheticEvent,
} from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Moment from "react-moment";
import { toast } from "react-toastify";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  debounce,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";

import style from "./purchaseOrder.module.css";
import * as rootStore from "stores/root_store";
import { PAGE_SIZE_DEFAULT } from "utils/constants";

import { HiSearch } from "react-icons/hi";
import { FaTimes } from "react-icons/fa";
import { TbLoaderQuarter } from "react-icons/tb";
import Pagination from "base/ui/components/Pagination/pagination";
import mock from "./mock";
import { Common } from "services/purchaseOrder_services";
import LoadingScreen from "base/ui/components/LoadingScreen/loadingScreen";

interface SearchPOFormModel {
  purchaseOrderCode: string;
  companyCode: string;
  documentType: string;
  vendorNumber: string;
  documentDateFrom: Date;
  documentDateTo: Date;
  productCode: string;
}

export function createPurchaseOrder() {
  return observer(() => {
    document.title = "Purchase Order- iMES WebPortal";
    // chuyển url
    const navigate = useNavigate();

    // =================== phân trang ==========================//
    // số lượng items trên 1 trang
    const [pageSize, setPageSize] = useState(PAGE_SIZE_DEFAULT);
    // tổng số trang
    const [pageCount, setPageCount] = useState(0);
    // bắt đầu từ item (start)
    const [currentPage, setcurrentPage] = useState(0);
    // trang được select -> trang hiện tại
    // mặc định trang là trang đầu tiên (trang 1 select = 0)
    // set -1 để không bị báo lỗi: (react-paginate): The forcePage prop provided is greater than the maximum page index from pageCount prop (0 > -1).
    const [pageSelected, setPageSelected] = useState(-1);

    // ================= Xem chi tiết po =======================//
    // xem chi tiết nhiều po => tạo mảng checkbox lưu vào localStorage
    const [checked, setChecked] = useState<string[]>([]);

    // ================= form tìm kiếm po =======================//
    // lưu lại companyCode để khi phân trang dữ liệu không bị reset
    const [companyCode, setCompanyCode] = useState(
      JSON.parse(localStorage.getItem("user_session") || "{}").companyCode
    );
    // lưu lại purchaseOrderCode để khi phân trang dữ liệu không bị reset
    // lưu lại documentType để khi phân trang dữ liệu không bị reset
    const [documentType, setDocumentType] = useState("");
    const [vendorNumber, setVendorNumber] = useState(""); // dùng để lưu mã Vendor, lưu để call api (vendorCode)
    const [vendorNumberTemp, setVendorNumberTemp] = useState(""); // dùng để lưu mã Vendor, lưu để call api (vendorCode)
    // material
    const [productCode, setProductCode] = useState("");
    // Ngày chứng từ
    const [documentDateFrom, setDocumentDateFrom] = useState<Date>(); // từ ngày
    const [documentDateTo, setDocumentDateTo] = useState<Date>(); // đến ngày

    // ===================Tìm kiếm vendor ======================//
    // lấy ref của popup search
    const popupRef = useRef<any>(null);
    // show hide tooltip
    const [show, setShow] = useState(false);
    // dữ liệu search input
    const [vendorSearchValue, setVendorSearchValue] = useState(""); //dùng để hiển thị trên ô ipput, cả mã vendor và tên (vendorDisplay)
    // search loading // dùng để loading lúc search
    // phần loading này chỉ dùng cho search
    const [searchLoading, setSearchLoading] = useState(false);

    // ================ Loading toàn trang ======================//
    // Loading của cả trang nếu gửi api thì cho loading
    const [loading, setLoading] = useState(true);
    // ===========================================================

    // ================== Duyệt purchase order =================//
    // đóng và mở modal duyệt
    const [open, setOpen] = useState(false);
    // lấy PO code của cần duyệt làm title cho modal
    const [modalTitle, setModalTitle] = useState("");
    // Lấy Purchase id để duyệt
    const [approvePOId, setApprovePOId] = useState("");
    // ============================================================

    // =============== Common Product ==============================
    // autocomplete
    const [product, setProduct] = useState("");
    // lấy dữ liệu trên store và gán lại cho state
    // const [productSearch, setProductSearch] = useState('');
    // loading khi gõ (search)
    const [materialSearchLoaded, setMaterialSearchLoaded] = useState(false);
    //================================================================

    // ============= tìm kiếm po theo từ khóa ========================//
    // chọn nhiều mã po lưu vào danh sách để search
    const [listPOCode, setListPOCode] = useState<string[]>();
    //================================================================//

    // kiểm tra nhà cung cấp hay hay nhân viên
    const [isVendor, setIsVendor] = useState(false);
    const [isPurchasing, setIsPurchasing] = useState(false);
    // tiêu đề của bảng PO
    // kiểm tra nhà cung cấp hay nhân viên
    // hiện ẩn các field theo nhóm quyền
    // isVendor == true -> visible = false
    const column = [
      {
        columnName: "purchaseOrderId",
        align: "text-center",
        visible: false,
      },
      { columnName: "STT", align: "text-center", visible: true },
      { columnName: "Chức năng", align: "text-center", visible: true },
      {
        columnName: "Gửi NCC",
        align: "text-center",
        visible: isPurchasing ? true : false,
      },
      {
        columnName: "Số PO",
        align: "text-center",
        visible: true,
      },
      {
        columnName: "Loại PO",
        align: "text-center",
        visible: isVendor ? false : true,
      },
      {
        columnName: "deletionInd",
        align: "",
        visible: false,
      },
      {
        columnName: "Plant",
        align: "text-center",
        visible: isVendor ? false : true,
      },
      {
        columnName: "Sale org",
        align: "text-center",
        visible: true,
      },
      {
        columnName: "NVL",
        align: "text-center",
        width: "max-w-[200px]",
        visible: true,
      },
      {
        columnName: "Mã NCC",
        align: "text-center",
        visible: isVendor ? false : true,
      },
      {
        columnName: "Tên NCC",
        align: "text-center",
        visible: isVendor ? false : true,
      },
      {
        columnName: "Ngày chứng từ",
        align: "text-center",
        visible: true,
      },
      { columnName: "active", align: "center", visible: false },
    ];
    // các row trong table lấy từ store
    const tableRows = rootStore.purchaseOrderStore.dataSearchPurchaseOrder.data;
    // Form validation
    const { register, handleSubmit } = useForm<SearchPOFormModel>();

    const [isCheckAll, setCheckAll] = useState(false);

    const handleUncheckedAll = () => {
      // Assuming `checked` is a state variable that contains the checked rows
      setChecked([]);

      var checkboxAll = document.querySelector("#checkAll") as HTMLInputElement;
      checkboxAll.checked = false;
    };

    useEffect(() => {
      const checkboxes = document.querySelectorAll(".row-checkbox");
      checkboxes.forEach((checkbox) => {
        var theCheckbox = checkbox as HTMLInputElement;
        theCheckbox.checked = isCheckAll;
      });
    }, [isCheckAll]);
    //
    // Kiểm tra role và lấy danh sách company
    useEffect(() => {
      setLoading(true);
      // kiểm tra vendor
      const userSession = JSON.parse(
        localStorage.getItem("user_session") || "{}"
      );
      const checkVendor = () => {
        if (userSession.role === "NCC") {
          setIsVendor(true);
        }
        if (userSession.role === "PURCHASING") {
          setIsPurchasing(true);
        }
      };
      // lấy danh sách company
      const getListCompany = async () => {
        await rootStore.companyPresenter.geListtCompany(
          rootStore.companyStore,
          userSession.userName
        );
      };

      checkVendor();
      getListCompany();
    }, []);

    // theo dỗi sự thay đổi của ref
    // thêm sự kiện mousedown trên popup nếu click ra ngoài thì setshow = false
    useEffect(() => {
      const handleClickAway = (e: any) => {
        if (popupRef.current && !popupRef.current.contains(e.target)) {
          setShow(false);
        }
      };
      document.addEventListener("mousedown", handleClickAway);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickAway);
      };
    }, [popupRef]);

    //xử lý thay đổi page size
    useEffect(() => {
      // cho loading
      setLoading(true);
      // call api thay đổi số item trên 1 trang
      const pagination = async () => {
        const result =
          await rootStore.purchaseOrderPresenter.searchPurchaseOrder(
            rootStore.purchaseOrderStore,
            {
              paging: {
                draw: 1,
                start: 0,
                length: pageSize,
              },
              companyCode: companyCode,
              purchaseOrderCode: listPOCode,
              documentType: documentType,
              vendorNumber: vendorNumber,
              documentDateFrom: documentDateFrom,
              documentDateTo: documentDateTo,
              productCode: productCode,
            }
          );

        if (result) {
          // số trang bằng tổng số item chia số item trên 1 trang :pageCount = recordsFiltered / pageSize (recordsFiltered lưu trong store =>lấy từ api)
          setPageCount(
            Math.ceil(
              rootStore.purchaseOrderStore.dataSearchPurchaseOrder
                .recordsFiltered / pageSize
            )
          );
          setLoading(false);
        }
        // nếu page size thay đổi thì chuyển về trang đầu tiên
        setPageSelected(0);
      };
      pagination();
    }, [pageSize]);

    // chuyển trang (page index)
    useEffect(() => {
      // cho loading khi chuyển trang
      setLoading(true);
      // thay đổi trang
      const pagination = async () => {
        await rootStore.purchaseOrderPresenter.searchPurchaseOrder(
          rootStore.purchaseOrderStore,
          {
            paging: {
              draw: 1,
              start: currentPage,
              length: pageSize,
            },
            companyCode: companyCode,
            purchaseOrderCode: listPOCode,
            documentType: documentType,
            vendorNumber: vendorNumber,
            documentDateFrom: documentDateFrom,
            documentDateTo: documentDateTo,
            productCode: productCode,
          }
        );
        // ngừng loading
        setLoading(false);
      };
      pagination();
    }, [currentPage]);

    // lấy value của select để set page size (thay đổi số lượng item trên trang)
    const handleSelectPageSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
      setPageSize(parseInt(e.target.value));
    };

    //  sự kiện click chuyển trang
    const handlePageClick = (data: any) => {
      setPageSelected(data.value);
      // lấy từ item số n (start)
      // trang 1 bắt đầu từ item thứ 0 ,
      // trang 2 bắt đầu từ item thứ (số trang -1) * (số item trên trang), vd pageSize = 10 => currentPage = (2-1)*10=10 -> start =10 // api trả về từ item thứ 11
      // data.selected để lấy ra số trang
      // vd trang = 1 thì data.selected = 0
      setcurrentPage(data.selected * pageSize);
    };
    // submit form tìm kiếm
    const submitForm = async (value: SearchPOFormModel) => {
      if (loading) {
        return;
      }

      // loading khi search
      setLoading(true);
      setCompanyCode(value.companyCode);
      // setPurchaseOrderCode(value.purchaseOrderCode);
      setDocumentType(value.documentType);
      setDocumentDateFrom(value.documentDateFrom);
      setDocumentDateTo(value.documentDateTo);
      setProductCode(product);
      setVendorNumber(vendorNumberTemp);
      const result = await rootStore.purchaseOrderPresenter.searchPurchaseOrder(
        rootStore.purchaseOrderStore,
        {
          paging: {
            draw: 1,
            start: 0,
            length: pageSize,
          },
          companyCode: value.companyCode,
          // purchaseOrderCode: value.purchaseOrderCode,
          purchaseOrderCode: listPOCode,
          documentType: value.documentType,
          vendorNumber: vendorNumberTemp,
          documentDateFrom: value.documentDateFrom,
          documentDateTo: value.documentDateTo,
          productCode: product,
        }
      );

      // nêu truy vấn thành công thì trả về trang 1
      if (result) {
        setcurrentPage(0);
        setPageSelected(0);
        // số trang = tổng số items (records) / số items trên trang
        setPageCount(
          Math.ceil(
            rootStore.purchaseOrderStore.dataSearchPurchaseOrder
              .recordsFiltered / pageSize
          )
        );
        // ngưng loading
        setLoading(false);
      }
    };

    //bắt sự kiện check box (chọn các po để xem chi tiết)
    // lưu mã các po đã check vào array
    const handleChecked = (id: string) => {
      setChecked((prev) => {
        const isChecked = checked.includes(id);
        if (isChecked) {
          return checked.filter((item) => item !== id);
        } else {
          return [...prev, id];
        }
      });
    };

    // xử lý sự kiện xem chi tiết nhiểu po (=> lưu list po id vào localStorage và chuyển trang)
    const handleShowDetail = async (id?: string) => {
      // nếu truyền id (id không rỗng) thì xem chi tiết 1 po
      if (id) {
        localStorage.setItem("po_ids", JSON.stringify([id]) || "{}");
        navigate("detail");
      } else {
        // // nếu không có po nào được chọn thì thông báo lỗi
        // kiểm tra xem các item có cùng nhà cung cấp hay không
        // gọi api để lấy message
        await rootStore.purchaseOrderPresenter.getPODetail(
          rootStore.purchaseOrderStore,
          {
            purchaseOrderIds: checked,
            paging: {
              draw: 1,
              start: 0,
              length: 10,
            },
          }
        );
        // nếu có data thì lưu mảng id vào localStorage
        // ngược lại thông báo lỗi ra màn hình
        if (rootStore.purchaseOrderStore.purchaseOrderDetail.data) {
          localStorage.setItem("po_ids", JSON.stringify(checked) || "{}");
          navigate("detail");
        } else {
          toast.error(rootStore.purchaseOrderStore.purchaseOrderDetail.message);
        }
      }
    };
    // sự kiện click chọn vendor sau khi tìm kiếm
    const handleSelectVendor = (vendorCode: string, vendorDisplay: string) => {
      // hiển thị mã và tên vendor lên ô input (gán value cho input search)
      setVendorSearchValue(vendorDisplay);
      setVendorNumberTemp(vendorCode); // lưu lại mã vendor để tìm kiếm po theo vendor
      setShow(false);
    };
    // sử dụng debounce để tránh gửi nhiều request, nếu ngưng gõ 1s thì call api
    const debounceDropDown = useCallback(
      debounce(async (nextValue) => {
        await rootStore.purchaseOrderPresenter.searchVendor(
          rootStore.purchaseOrderStore,
          nextValue
        );
        setSearchLoading(false);
      }, 1000),
      []
    );

    // xử lý searchVendor
    const handlSearchVendor = async (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      // cho loading khi tìm kiếm
      // ngưng loading khi có kết quả tìm kiếm
      setSearchLoading(true);
      setVendorSearchValue(e.target.value);
      // rootStore.purchaseOrderPresenter.searchVendor(rootStore.purchaseOrderStore, e.target.value);
      await debounceDropDown(e.target.value);
    };

    // xóa value trên input search vendor và mã vendor (vendorNumber)
    const handlClearSearchValue = () => {
      setVendorSearchValue("");
      setVendorNumber("");
      setVendorNumberTemp("");
    };

    // ========================= Xử lý duyệt PO =============== =================//
    // Hàm đóng modal
    const handleCloseModal = () => {
      setOpen(false);
      setModalTitle("");
    };
    // Hiện modal duyệt
    const handleShowModal = (
      purchaseOrderCode: string,
      purchaseOrderId: string
    ) => {
      // set title cho modal  = số po
      setModalTitle(purchaseOrderCode);
      setApprovePOId(purchaseOrderId);
      // cho hiện modal lên
      setOpen(true);
    };

    const [isDuyetSelected, setIsDuyetSelected] = useState(false);

    const handleShowModalDuyetAll = () => {
      console.log(checked);
      // set title cho modal  = số po
      setModalTitle("đã chọn");
      setIsDuyetSelected(true);
      setOpen(true);
    };

    // xử lý duyệt po
    const handleApprove = async () => {
      //gọi api duyệt
      setLoading(true);

      if (isDuyetSelected) {
        await rootStore.purchaseOrderPresenter.approveViewVendor(
          rootStore.purchaseOrderStore,
          {
            purchaseOrderIds: checked,
          }
        );
      } else {
        await rootStore.purchaseOrderPresenter.approveViewVendor(
          rootStore.purchaseOrderStore,
          {
            purchaseOrderIds: [approvePOId],
          }
        );
      }
      // kiểm tra nếu response trả về là success thì hiện thông báo duyệt thành công
      if (rootStore.purchaseOrderStore.approveViewVendorResponse.isSuccess) {
        // gọi api reload lai trạng thái
        await rootStore.purchaseOrderPresenter.searchPurchaseOrder(
          rootStore.purchaseOrderStore,
          {
            paging: {
              draw: 1,
              start: currentPage,
              length: pageSize,
            },
            companyCode: companyCode,
            purchaseOrderCode: listPOCode,
            documentType: documentType,
            vendorNumber: vendorNumber,
            productCode: productCode,
          }
        );
        // thông báo thành công
        toast.success(
          rootStore.purchaseOrderStore.approveViewVendorResponse.message
        );
      }
      // ngược lại thông báo duyệt thất bại
      else {
        toast.error(
          rootStore.purchaseOrderStore.approveViewVendorResponse.message
        );
      }
      // Xóa title của modal và đóng modal
      setModalTitle("");
      setApprovePOId("");
      setOpen(false);
      setLoading(false);
      setIsDuyetSelected(false);
      handleUncheckedAll();
    };
    // ==========================================================================//

    // ============== commonProduct =================================================================
    const debounceProduct = useCallback(
      debounce(async (nextValue) => {
        await rootStore.purchaseOrderPresenter.getProduct(
          rootStore.purchaseOrderStore,
          nextValue
        );
        setMaterialSearchLoaded(false);
      }, 100),
      []
    );
    const handleChangAutoComplete = async (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      // setProductSearch(e.target.value);
      if (e.target.value) {
        setMaterialSearchLoaded(true);
        await debounceProduct(e.target.value);
      }
    };

    const handleChangeProductCode = (
      e: SyntheticEvent<Element, Event>,
      items: any
    ) => {
      if (items) {
        setProduct(items.key);
      } else {
        setProduct("");
      }
    };

    // tìm kiếm purchase order code
    const debounceSearchPOCode = useCallback(
      debounce(async (nextValue) => {
        await rootStore.purchaseOrderPresenter.getDropDownPo(
          rootStore.purchaseOrderStore,
          nextValue
        );
      }, 100),
      []
    );
    const handleSearchPOCode = async (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      if (e.target.value) {
        await debounceSearchPOCode(e.target.value);
      }
    };
    const handleGetListPOCode = async (
      e: SyntheticEvent<Element, Event>,
      items: Common[]
    ) => {
      if (items) {
        const listItem = items.map((item) => {
          return item.key;
        });
        setListPOCode(listItem);
      }
      // else {
      //     setListPOCode([]);
      // }
    };

    return (
      <div className="w-full py-3 px-5">
        {loading && (
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        <h3 className="text-md font-bold text-gray-800 mb-2 uppercase">
          Danh sách PO
        </h3>
        {/* Form nhập tiềm kiếm */}
        <div className="w-full bg-white py-3 flex rounded mb-2 border border-neutral-300">
          <form
            onSubmit={handleSubmit(submitForm)}
            className="w-full px-3 py-2 "
          >
            <div className="w-full flex flex-col md:flex-col lg:flex-row xl:flex-row sm:flex-col ">
              <div className="lg:w-1/2 md:w-full sm:w-full">
                {/* Tìm kiếm theo mã po */}
                <div className="w-full flex flex-col sm:flex-row lg:px-20 lg:pl-3 px-3 mb-3 sm:items-center">
                  <label className="min-w-[150px] font-medium text-default text-gray-700 sm:text-end">
                    Purchase order
                  </label>
                  {/* tìm kiếm 1 po */}
                  {/* <input
                                        {...register('purchaseOrderCode')}
                                        className="text-gray-700 border border-gray-300 sm:ml-5 px-3 py-1.5 text-default flex-1 focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                                    /> */}
                  {/* Tìm nhiều po code cùng lúc */}
                  <Autocomplete
                    multiple
                    options={rootStore.purchaseOrderStore.commonPOCode.data}
                    limitTags={2}
                    getOptionLabel={(option) => option.value}
                    onChange={handleGetListPOCode}
                    renderInput={(params) => (
                      <TextField {...params} onChange={handleSearchPOCode} />
                    )}
                    className="text-gray-700 sm:ml-5 text-default flex-1 caret-blue-600"
                    classes={{
                      input: "rounded-0 !text-[14px]",
                      focused: "!border-blue-500",
                      listbox: "text-[13px]",
                      noOptions: "text-default",
                    }}
                    sx={{
                      width: 300,
                      ".MuiInputBase-root": {
                        borderRadius: 0,
                        padding: 0,
                      },
                      ".MuiOutlinedInput-notchedOutline0": {
                        borderColor: "#d1d5db",
                      },
                      ".MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#d1d5db",
                        },
                      ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderWidth: "1px",
                        },
                      ".MuiAutocomplete-tag": {
                        height: "auto",
                      },
                    }}
                  />
                </div>
                {!isVendor && (
                  <div className="w-full flex flex-col sm:flex-row lg:px-20 lg:pl-3 px-3 mb-3 sm:items-center">
                    <label className="min-w-[150px] font-medium text-default text-gray-700 sm:text-end">
                      Plant
                    </label>
                    {/* <select
                                            {...register('companyCode')}
                                            className="text-gray-700 border border-gray-300 sm:ml-5 px-3 py-1.5 text-default w-full focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                                        >
                                            <option className="" value="">
                                                -- Chọn công ty --
                                            </option>
                                            {rootStore.companyStore.companies.map((company, index) => (
                                                <option value={company.companyCode} key={index}>
                                                    {company.companyName}
                                                </option>
                                            ))}
                                        </select> */}
                    <FormControl fullWidth>
                      <Select
                        {...register("companyCode")}
                        displayEmpty
                        value={companyCode}
                        onChange={(e) => setCompanyCode(e.target.value)}
                        size="small"
                        className="text-gray-700 sm:ml-5 !text-default w-full"
                        classes={{
                          select: "rounded-0",
                        }}
                        sx={{
                          width: "auto",
                          ".MuiInputBase-root": {
                            borderRadius: 0,
                            padding: 0,
                          },
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "#d1d5db",
                            borderRadius: 0,
                          },
                          "MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#d1d5db",
                            },
                          ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderWidth: "1px",
                            },
                        }}
                      >
                        <MenuItem className="!text-default" value="">
                          -- Chọn công ty --
                        </MenuItem>
                        {rootStore.companyStore.companies.map(
                          (company, index) => (
                            <MenuItem
                              value={company.companyCode}
                              key={index}
                              className="!text-default"
                            >
                              {company.companyName}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>
              <div className="lg:w-1/2 md:w-full sm:w-full">
                <div className="w-full flex flex-col sm:flex-row lg:px-20 lg:pl-3 px-3 mb-3 sm:items-center">
                  <label className="min-w-[150px] font-medium text-default text-gray-700 sm:text-end">
                    Material
                  </label>

                  <Autocomplete
                    {...register("productCode")}
                    className="text-gray-700 sm:ml-5 text-default flex-1 caret-blue-600"
                    disablePortal
                    // freeSolo
                    id="combo-box-demo"
                    loading={materialSearchLoaded}
                    classes={{
                      input: "rounded-0 !text-[14px]",
                      focused: "!border-blue-500",
                      listbox: "text-[13px]",
                      noOptions: "text-default",
                    }}
                    filterOptions={(options, state) => options}
                    options={rootStore.purchaseOrderStore.commonProduct.data}
                    getOptionLabel={(option) => option.value}
                    sx={{
                      width: 300,
                      ".MuiInputBase-root": {
                        borderRadius: 0,
                        padding: 0,
                      },
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#d1d5db",
                      },
                      ".MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#d1d5db",
                        },
                      ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderWidth: "1px",
                        },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {materialSearchLoaded ? (
                                <CircularProgress color="inherit" size={10} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                        onChange={handleChangAutoComplete}
                      />
                    )}
                    onChange={handleChangeProductCode}
                  />
                </div>
                {/* ẩn khi role là nhà cung cấp */}
                {!isVendor && (
                  <>
                    <div className="w-full flex flex-col sm:flex-row lg:px-20 lg:pl-3 px-3 mb-3 sm:items-center">
                      <label className="min-w-[150px] font-medium text-default text-gray-700 sm:text-end">
                        Document type
                      </label>
                      <input
                        {...register("documentType")}
                        className="text-gray-700 border border-gray-300 sm:ml-5 px-3 py-1.5 text-default flex-1 focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                      />
                    </div>
                    {/* tìm theo Vendor number */}
                    {/* search vendor  */}
                    <div className="w-full flex flex-col sm:flex-row lg:px-20 lg:pl-3 px-3 mb-3 sm:items-center">
                      <label className="min-w-[150px] font-medium text-default text-gray-700 sm:text-end">
                        Vendor number
                      </label>
                      <div className="relative flex items-center w-full sm:ml-5">
                        <input
                          // {...register('vendorNumber')}
                          type="text"
                          value={vendorSearchValue}
                          onClick={() => setShow(true)}
                          onChange={handlSearchVendor}
                          className={`w-full text-gray-700 border border-gray-300 rounded-r-none px-3 py-1.5 text-default flex-1 focus-visible:outline-none focus:border-blue-500 caret-blue-600 disabled:bg-gray-100`}
                        />
                        {/* nếu có dữ liệu thì hiện popup lên cho chọn vendor */}
                        {show ? (
                          <ul
                            ref={popupRef}
                            className={`absolute w-full max-h-[200px] overflow-y-auto bg-white top-9 shadow-md shadow-gray-300 text-default`}
                          >
                            {rootStore.purchaseOrderStore.listVendor
                              ? rootStore.purchaseOrderStore.listVendor.data.map(
                                  (vendor) => (
                                    <li
                                      onClick={() =>
                                        handleSelectVendor(
                                          vendor.vendorCode,
                                          vendor.vendorDisplay
                                        )
                                      }
                                      key={vendor.vendorCode}
                                      className="w-full px-3 py-2 bg-gray-50 cursor-pointer hover:bg-gray-200"
                                    >
                                      {vendor.vendorDisplay}
                                    </li>
                                  )
                                )
                              : ""}
                          </ul>
                        ) : (
                          ""
                        )}
                        <span
                          onClick={handlClearSearchValue}
                          className="min-w-[34px] min-h-[34px] flex justify-center items-center border border-l-0 border-gray-300 cursor-pointer hover:bg-red-500 hover:text-white hover:border-red-500 text-xs"
                        >
                          {searchLoading ? (
                            <TbLoaderQuarter className="animate-spin" />
                          ) : (
                            <FaTimes />
                          )}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                {/* nhà cung cấp tra cứu  */}
              </div>
            </div>
            <h3 className="font-medium text-default lg:px-16 lg:pr-5 sm:px-16 px-3 mb-3 text-gray-700">
              Ngày chứng từ
            </h3>
            <div className="w-full flex flex-col md:flex-col lg:flex-row xl:flex-row sm:flex-col mb-3">
              <div className="lg:w-1/2 md:w-full sm:w-full lg:mb-0 mb-3 flex flex-col sm:flex-row lg:px-20 lg:pl-3 px-3 sm:items-center">
                <label className="min-w-[150px] font-medium text-default text-gray-700 sm:text-end">
                  Từ ngày
                </label>
                <input
                  {...register("documentDateFrom")}
                  type="date"
                  className="text-gray-700 border border-gray-300 sm:ml-5 px-3 py-1.5 text-default flex-1 focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                />
              </div>
              <div className="lg:w-1/2 md:w-full sm:w-full flex flex-col sm:flex-row lg:px-20 lg:pl-3 px-3 sm:items-center">
                <label className="min-w-[150px] font-medium text-default text-gray-700 sm:text-end">
                  Đến ngày
                </label>
                <input
                  {...register("documentDateTo")}
                  type="date"
                  className="text-gray-700 border border-gray-300 sm:ml-5 px-3 py-1.5 text-default flex-1 focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                />
              </div>
            </div>
            <div className="w-full flex justify-center">
              <button
                type="submit"
                className={`flex items-center text-white bg-blue-700 rounded text-default px-2.5 py-1 text-center focus:outline-none hover:bg-blue-600 ${
                  loading && "!bg-blue-600"
                }`}
              >
                {loading ? (
                  <>
                    <TbLoaderQuarter className="animate-spin" />
                    Loading...
                  </>
                ) : (
                  <>
                    <HiSearch className="font-bold text-lg" />
                    Tìm kiếm
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
        {/* <VendorModal open={showModal} onClick={handleShowModal} /> */}
        {/* ============ Table danh sách PO ====================*/}

        <div className="w-full bg-white py-3 px-5 rounded border border-neutral-300">
          {/*=========== Pagination =======================*/}
          <Pagination
            pageCount={pageCount}
            forcePage={pageSelected}
            onPageChange={handlePageClick}
            onPageSizeChange={handleSelectPageSize}
          />
          {/* button xen chi tiết nhiều po */}
          <div className="w-full my-2">
            <button
              type="button"
              onClick={() => handleShowDetail()}
              className="flex items-center text-white bg-blue-700 rounded text-default px-2.5 py-1 text-center focus:outline-none hover:bg-blue-600"
            >
              Xem chi tiết
            </button>
          </div>
          {/* =========== Table PO ===============*/}
          <div className={`overflow-x-auto my-3`}>
            <table className="w-full table-auto border-collapse space-collapse mb-5 text-default">
              <thead className="font-medium text-gray-700 bg-gray-100">
                <tr>
                  {column.map((item, index) => {
                    if (item.visible) {
                      return (
                        <th
                          key={index}
                          scope="col"
                          className={`${
                            item.visible ? "" : "hidden"
                          } py-2 px-2 border whitespace-nowrap text-left ${
                            item.align
                          }`}
                        >
                          {item.columnName}
                          {item.columnName === "Chức năng" && isPurchasing && (
                            <div>
                              <input
                                type="checkbox"
                                id="checkAll"
                                onChange={(e) => {
                                  setCheckAll(e.target.checked);
                                  if (e.target.checked) {
                                    tableRows.forEach((row) =>
                                      handleChecked(row.purchaseOrderId)
                                    );
                                  } else {
                                    handleUncheckedAll();
                                  }
                                }}
                              />
                            </div>
                          )}
                        </th>
                      );
                    }
                  })}
                </tr>
              </thead>
              <tbody>
                {tableRows.length !== 0 ? (
                  tableRows.map((row, index) => (
                    <tr
                      key={index}
                      className={`${
                        index % 2 === 0 ? "bg-gray-50" : "bg-white"
                      }`}
                    >
                      {/* Số thứ tự */}
                      <td className="py-1.5 px-2 border whitespace-nowrap text-center">
                        {row.stt}
                      </td>
                      {/* Chức năng  */}
                      {/* Xem chi tiết nhiều po */}
                      <td className="py-1.5 px-2 border whitespace-nowrap text-center">
                        <input
                          checked={checked.includes(row.purchaseOrderId)}
                          onChange={() => handleChecked(row.purchaseOrderId)}
                          type="checkbox"
                          className="row-checkbox"
                        />
                      </td>
                      {isPurchasing && (
                        <td className="py-1.5 px-2 border whitespace-nowrap text-center">
                          {row.isVendorView ? (
                            <button className="min-w-[72px] rounded-sm bg-success/70 py-0.5 px-2.5 text-default text-white cursor-default">
                              Đã duyệt
                            </button>
                          ) : (
                            <button
                              onClick={() =>
                                handleShowModal(
                                  row.purchaseOrderCode,
                                  row.purchaseOrderId
                                )
                              }
                              className="min-w-[72px] rounded-sm bg-success  hover:bg-success/80 py-0.5 px-2.5 text-default text-white"
                            >
                              Duyệt
                            </button>
                          )}
                        </td>
                      )}
                      {/* Số po */}
                      {/* click vào để xem chi tiết */}
                      <td
                        onClick={() => handleShowDetail(row.purchaseOrderId)}
                        className="py-1.5 px-2 border whitespace-nowrap cursor-pointer text-sky-700 hover:text-sky-600 text-center"
                      >
                        {row.purchaseOrderCode}
                      </td>
                      {/* Loại Po */}
                      {/* là tk nhân viên thì hiển thị */}
                      {!isVendor && (
                        <td className="py-1.5 px-2 border whitespace-nowrap text-center">
                          {row.documentType}
                        </td>
                      )}
                      {/* Mã công ty */}
                      {/* là tk nhân viên thì hiển thị */}
                      {!isVendor && (
                        <td className="py-1.5 px-2 border whitespace-nowrap text-center">
                          {row.companyCode}
                        </td>
                      )}
                      {/* mã tổ chức mua hàng */}
                      <td className="py-1.5 px-2 border whitespace-nowrap text-center">
                        {row.purchasingOrg}
                      </td>
                      {/* Nguyên vật liệu */}
                      <Tooltip title={`${row.productCodeName}`}>
                        <td className="py-1.5 px-2 border text-default max-w-[250px] truncate">
                          <span>{row.productCodeName}</span>
                        </td>
                      </Tooltip>
                      {/* Mã nhà cung cấp */}
                      {/* là tk nhân viên thì hiển thị */}
                      {!isVendor && (
                        <td className="py-1.5 px-2 border whitespace-nowrap text-center">
                          {row.vendorNumber}
                        </td>
                      )}
                      {/* Tên nhà cung cấp */}
                      {/* là tk nhân viên thì hiển thị */}
                      {!isVendor && (
                        <Tooltip title={`${row.vendorName}`}>
                          <td className="py-1.5 px-2 border whitespace-nowrap max-w-[250px] truncate">
                            {row.vendorName}
                          </td>
                        </Tooltip>
                      )}
                      {/* Ngày chứng từ */}
                      <td className="py-1.5 px-2 border whitespace-nowrap text-center">
                        <Moment format="DD/MM/YYYY">{row.documentDate}</Moment>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={column.length}
                      className="py-1.5 px-2 text-default border whitespace-nowrap text-center"
                    >
                      Không tìm thấy dòng nào phù hợp
                    </td>
                  </tr>
                )}
              </tbody>
              {isPurchasing && (
                <tfoot>
                  <tr className="py-1.5 px-2 border whitespace-nowrap text-center">
                    <td className="py-1.5 px-2 border whitespace-nowrap text-center"></td>
                    <td className="py-1.5 px-2 border whitespace-nowrap text-center">
                      <button
                        onClick={() => handleShowModalDuyetAll()}
                        className="min-w-[72px] rounded-sm bg-success  hover:bg-success/80 py-0.5 px-2.5 text-default text-white"
                      >
                        Duyệt các PO đã chọn
                      </button>
                    </td>
                    <td
                      className="py-1.5 px-2 border whitespace-nowrap text-center"
                      rowSpan={8}
                    ></td>
                  </tr>
                </tfoot>
              )}
            </table>
          </div>
          <div
            className={`w-full h-screen fixed bg-modal inset-0 z-[100] ${
              !open && "hidden"
            }`}
          >
            <div className="fixed w-[450px] bg-white top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 shadow-xl p-5 shadow-modal text-default rounded border-t-2 border-t-sky-700">
              <div className="text-center">
                <h2 className="text-base font-medium mb-2">
                  Duyệt purchase order <span>{modalTitle}</span>
                </h2>
                <p>
                  Nhà cung cấp sẽ thấy Purchase này nếu bạn nhấn nút "Xác nhận"
                </p>
              </div>
              <div className="flex justify-between mt-3">
                <button
                  type="button"
                  onClick={handleCloseModal}
                  className="rounded-sm bg-gray-400 hover:bg-gray-400/90 px-3 py-1 min-w-[80px]"
                >
                  Hủy
                </button>
                <button
                  type="button"
                  onClick={handleApprove}
                  className={`rounded-sm bg-success hover:bg-success/90 px-3 py-1 min-w-[80px] text-white ${
                    loading && "!bg-success/90"
                  }`}
                >
                  {loading ? "Đang duyệt..." : "Xác nhận"}
                </button>
              </div>
            </div>
          </div>
          <span className="block my-4 text-default">
            Đang xem {`${currentPage + 1}`} đến {`${currentPage + pageSize}`}{" "}
            trong tổng số{" "}
            {`${rootStore.purchaseOrderStore.dataSearchPurchaseOrder.recordsFiltered}`}{" "}
            mục
          </span>
        </div>
      </div>
    );
  });
}
