import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import * as rootStore from "stores/root_store";
import { IPurchaseOrderServices } from "services/purchaseOrder_services";
import { createPurchaseOrderPresenter } from "stores/purchaseOrder_store";
import { ListItemPO, PurchaseOrderDetails } from "services/barcode_service";
import { toast } from "react-toastify";
import { BASE_API_BARCODE } from "utils/constants";

import ReactToPrint from "react-to-print";
import ContentToPrint from "./contenToPrint";
import { IoArrowBackCircle } from "react-icons/io5";
import { FaTimes, FaXbox } from "react-icons/fa";
import { Backdrop, CircularProgress } from "@mui/material";
import logo from "assets/ttf-black-white.png"; // Update the path as necessary
import { BiCheckbox } from "react-icons/bi";

export type FormSaveBarcodeModel = {
  materialType: string;
  vendorCode: string;
  quantity: number;
  plant: string;
  productCode: string;
  poQuantity: number;
  poQuantityUnit: string;
  specifications: string;
  unit: string;
  quantityConversion: number;
  quantityConversionUnit: string;
  weight: number;
  weightUnit: string;
  manufacturingDate: Date;
  expirationDate: Date;
  poDetailQuantitys: PurchaseOrderDetails[];
};

const schema = yup.object().shape({
  // quantity: yup.number().required(),
  // weight: yup.number(),
  // weightUnit: yup.string(),
  // specifications: yup.string().notRequired(),
  // quantityConversion: yup.number().notRequired(),
  // quantityConversionUnit: yup.string().notRequired(),
  // manufacturingDate: yup.date().notRequired(),
  // expirationDate: yup.date().notRequired(),
  poDetailQuantitys: yup.array().of(
    yup.object().shape({
      quantity: yup.number(),
      po: yup.string(),
      poLine: yup.string(),
      so: yup.string(),
      soLine: yup.string(),
      wbs: yup.string(),
    })
  ),
});

export function createPrintBarcode(purchaseOrderServices: { purchaseOrderServices: IPurchaseOrderServices }) {
  const presenter = rootStore.barcodePrensenter;
  const store = rootStore.barcodeStore;

  return observer(() => {
    document.title = "Barcode NVL- iMES WebPortal";
    // lưu trạng thái của radio button (materialType)
    const [type, setType] = useState("1");
    let total = 0;
    // số lượng quantity theo Po
    // lấy tất cả quantity theo PO line đưa vô mảng
    const [quantity, setQuanttiy] = useState<number[]>([]);
    // kiểm tra tạo barcode rồi thì không cho bấm tạo nữa
    const [disabled, setDisabled] = useState<boolean>(false);
    // ẩn hiện modal
    const [showModal, setShowModal] = useState<boolean>(false);
    // navigate chuyển trang của  react-router-dom
    const navigate = useNavigate();
    // lấy danh sách các po detail id từ localStorage
    const dataPOItem = JSON.parse(localStorage.getItem("listitem_PO") || "{}");
    // lấy thông tin user từ localStorage
    const userSession = JSON.parse(localStorage.getItem("user_session") || "{}");
    // mảng material type fix cứng nếu muốn thêm type thì chỉ cần thêm vô mảng
    const [loading, setLoading] = useState(true);
    const materialType = [
      { materialTypeId: "1", materialTypeName: "NVL chính" },
      { materialTypeId: "2", materialTypeName: "Hóa chất" },
      { materialTypeId: "3", materialTypeName: "Vật tư khác" },
    ];
    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm<FormSaveBarcodeModel>({
      resolver: yupResolver(schema),
    });

    useEffect(() => {
      const fetchData = async () => {
        await presenter.getListItemPO(store, dataPOItem);
        setLoading(false);
      };
      fetchData();
    }, []);

    store.listItemPO?.data?.purchaseOrderDetails.map((item) => {
      total += item.quantityReceived;
    });

    const printRef = useRef() as React.MutableRefObject<HTMLDivElement>;

    const onSubmit = async (value: FormSaveBarcodeModel) => {
      if (store.listItemPO && store.listItemPO.data) {
        //    set các trường từ api
        value.vendorCode = store.listItemPO.data.vendorCode;
        value.plant = userSession.companyCode;
        // value.poDetails = dataPOItem.poDetails;
        value.productCode = store.listItemPO.data.productCode;
        value.poQuantity = store.listItemPO.data.poQuantity;
        value.poQuantityUnit = store.listItemPO.data.poQuantityUnit;
        value.weightUnit = "Kgs";
        value.unit = store.listItemPO.data.poQuantityUnit;
        setDisabled(true);
        await presenter.saveBarcode(store, value);
        // Kiểm tra lưu thành công hay chưa
        // nêu thành công thông báo thành công và call api lấy barcode
        // thất bại => thông báo lỗi
        if (store.dataSaveBarcode.isSuccess) {
          const rawMaterialCardId = store.dataSaveBarcode.data;
          await presenter.getBarcode(store, rawMaterialCardId);
          toast.success(store.dataSaveBarcode.message);
          setDisabled(true);
          setShowModal(true);
        } else {
          toast.error("Thêm mới barcode thất bại.");
        }
      }
    };
    // chọn material type
    function handleChangType(e: React.ChangeEvent<HTMLSelectElement>) {
      setType(e.target.value);
    }
    // Tính tổng số lượng giao hàng
    const handleChangQuantity = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      // kiểm tra nếu value k rỗng thì value = e.taget.vale
      const value = parseFloat(e.target.value) || 0;
      const temp = [...quantity];
      temp[index] = value;
      setQuanttiy(temp);
    };

    const handleClosePrintmodal = () => {
      setShowModal(!showModal);
      window.history.back();
    };
    const handlePrint = () => {
      // setTimeout(() => {
      //     window.history.back();
      // }, 500);
    };

    return (
      <div className="w-full p-5 min-h-screen">
        {/* back lại trnag trước */}
        <span
          onClick={() => window.history.back()}
          className="flex items-center text-xs text-primary cursor-pointer mb-3 font-medium"
        >
          <IoArrowBackCircle className="mr-2 text-base text-gray-500" />
          Quay lại
        </span>
        {/* tiêu đề trang */}
        <div className="w-full px-5 py-2 bg-white rounded rounded-b-none flex border border-t-2 border-t-sky-700 border-neutral-300">
          <h3 className="text-md font-bold text-gray-800 uppercase">barcode pallet NVL</h3>
        </div>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <div className="w-full p-5 bg-white rounded mb-2 rounded-t-none border border-t-0 border-neutral-300">
          <div className="w-full">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="w-full text-default mb-2">
                {/* chọn material type (fix cứng) */}
                <label htmlFor="" className="font-medium mr-3">
                  Material Type
                </label>
                {/* chọn bằng các raido button */}
                <div className="block sm:flex mt-2 justify-between w-full md:w-1/2">
                  {/* render material type */}
                  {materialType.map((item) => (
                    <div key={item.materialTypeId} className="flex">
                      <input
                        checked={type === item.materialTypeId}
                        name="materialType"
                        type="radio"
                        id={`materialType${item.materialTypeId}`}
                        className="mr-2"
                        onChange={() => setType(item.materialTypeId)}
                      />
                      <label htmlFor={`materialType${item.materialTypeId}`}>{item.materialTypeName}</label>
                    </div>
                  ))}
                </div>
              </div>
              {/* Mã nhà cung cấp */}
              <div className="w-full sm:block lg:flex">
                <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-default sm:pr-0 lg:pr-10">
                  <label className="font-medium text-gray-700">Mã NCC</label>
                  {/* hiển thị mã ncc */}
                  <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100">
                    {store.listItemPO?.data?.vendorCode}
                  </span>
                </div>
                <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-default">
                  <label className="font-medium text-gray-700">Tên NCC</label>
                  <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100">
                    {store.listItemPO?.data?.vendorName}
                  </span>
                </div>
              </div>
              <div className="w-full sm:block lg:flex">
                <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-default sm:pr-0 lg:pr-10">
                  <label className="font-medium text-gray-700">Mã hàng</label>
                  <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100">
                    {store.listItemPO?.data?.productCode}
                  </span>
                </div>
                <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-default">
                  <label className="font-medium text-gray-700">Tên hàng</label>
                  <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100">
                    {store.listItemPO?.data?.productName}
                  </span>
                </div>
              </div>
              {/* số lượng đặt hàng */}
              <div className="w-full sm:block lg:flex">
                <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-default sm:pr-0 lg:pr-10">
                  <label className="font-medium text-gray-700">Số lượng đặt hàng</label>
                  <input
                    type="text"
                    className="block w-full mt-1 outline-none py-1.5 px-3 border disabled:bg-gray-100 lg:text-end"
                    disabled
                    // format number => .toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                    value={store.listItemPO?.data?.poQuantity.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                  />
                </div>
                <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-default">
                  <label className="font-medium text-gray-700">Đơn vị tính</label>
                  <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100">
                    {store.listItemPO?.data?.poQuantityUnit}
                  </span>
                </div>
              </div>
              <div className="w-full sm:block lg:flex">
                <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-default sm:pr-0 lg:pr-10">
                  <label className="font-medium text-gray-700">Số lượng đã nhập kho</label>
                  <input
                    type="text"
                    className="block w-full mt-1 outline-none py-1.5 px-3 border disabled:bg-gray-100 lg:text-end"
                    disabled
                    value={total.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                  />
                </div>
                <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-default">
                  <label className="font-medium text-gray-700">Đơn vị tính</label>
                  <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100">
                    {store.listItemPO?.data?.poQuantityUnit}
                  </span>
                </div>
              </div>
              {/* field số lương giao hàng  */}
              {/* Kiểm tra số lượng giao hàng */}
              <div className="w-full sm:block lg:flex">
                <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-default sm:pr-0 lg:pr-10">
                  <label className="font-medium text-gray-700">Số lượng giao hàng</label>
                  <input
                    type="text"
                    className="block w-full mt-1 outline-none py-1.5 px-3 border disabled:bg-gray-100 lg:text-end"
                    disabled
                    // nếu như mảng quantity khác rỗng thì tính tổng của mảng (dùng reduce để tính toán)
                    // format số (lấy 2 số thập phân)
                    value={
                      quantity.length > 0
                        ? quantity
                            .reduce((total, item) => total + item)
                            .toFixed(3)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                        : 0
                    }
                  />
                </div>
                <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-default">
                  <label className="font-medium text-gray-700">Đơn vị tính</label>
                  <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100">
                    {store.listItemPO?.data?.poQuantityUnit}
                  </span>
                </div>
              </div>
              <div className="w-full sm:block lg:flex">
                <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-default sm:pr-0 lg:pr-10">
                  <label className="font-medium text-gray-700">Quy cách</label>
                  <input
                    {...register("specifications")}
                    className="block w-full mt-1 outline-none py-1.5 px-3 border focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                  />
                  {/* thông báo lỗi */}
                  {errors.weight && <p className="!mt-1 text-xs text-red-500 pl-5">{`${errors.weight.message}`}</p>}
                </div>
                <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-default"></div>
              </div>
              {type === "1" && (
                <div className="w-full sm:block lg:flex">
                  <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-default sm:pr-0 lg:pr-10">
                    <label className="font-medium text-gray-700">Số lượng quy đổi</label>
                    <input
                      {...register("quantityConversion")}
                      type="number"
                      min="0"
                      className="block w-full mt-1 outline-none py-1.5 px-3 border lg:text-end focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                    />
                    {/* thông báo lỗi */}
                    {errors.weight && <p className="!mt-1 text-xs text-red-500 pl-5">{`${errors.weight.message}`}</p>}
                  </div>
                  <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-default">
                    <label className="font-medium text-gray-700">Đơn vị tính</label>
                    <input
                      {...register("quantityConversionUnit")}
                      type="text"
                      className="block w-full mt-1 outline-none py-1.5 px-3 border focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                    />
                    {/* thông báo lỗi */}
                    {errors.weightUnit && (
                      <p className="!mt-1 text-xs text-red-500 pl-5">{`${errors.weightUnit.message}`}</p>
                    )}
                  </div>
                </div>
              )}
              <div className="w-full sm:block lg:flex">
                {/* kiểm tra material type và hiển thị ra màn hình nếu là type 1 thì cho width = 1/2 */}
                <div className={`mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 sm:pr-0 lg:pr-10 text-default`}>
                  <label htmlFor="manufacturingDate" className="font-medium text-gray-700">
                    Ngày sản xuất
                  </label>
                  <input
                    {...register("manufacturingDate")}
                    id="manufacturingDate"
                    type="date"
                    className="block w-full mt-1 outline-none py-1.5 px-3 border focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                  />
                </div>
                {/* Kiểm tra materialType (type =2) */}
                {type === "2" && (
                  <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-default">
                    <label htmlFor="expirationDate" className="font-medium text-gray-700">
                      Ngày hết hạn
                    </label>
                    <input
                      {...register("expirationDate")}
                      id="expirationDate"
                      type="date"
                      // onChange={(e)=>handleChangeExpDate(e)}
                      // value={store.listItemPO?.data.}
                      className="block w-full mt-1 outline-none py-1.5 px-3 border focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                    />
                  </div>
                )}
              </div>
              {/* Bảng số lượng giao hàng theo PO/PO line */}
              <div className="w-full mt-2 text-default overflow-x-auto">
                <label className="font-medium text-gray-700">Số lượng giao hàng theo PO/PO Line</label>
                <table className="w-full table-auto border-collapse border space-collapse mb-5 mt-1">
                  <thead className="text-gray-700 bg-gray-50 border-b-2">
                    <tr>
                      <th className="py-1.5 px-3 whitespace-nowrap border text-gray-700 font-medium">PO/PO Line</th>
                      <th className="py-1.5 px-3 whitespace-nowrap border text-gray-700 font-medium">SO/WBS</th>
                      <th className="py-1.5 px-3 whitespace-nowrap border text-gray-700 font-medium">
                        Số lượng giao hàng
                      </th>
                      <th className="py-1.5 px-3 whitespace-nowrap border text-gray-700 font-medium">Đơn vị tính</th>
                      <th className="py-1.5 px-3 whitespace-nowrap border text-gray-700 font-medium">
                        Số lượng theo PO/PO Line
                      </th>
                      <th className="py-1.5 px-3 whitespace-nowrap border text-gray-700 font-medium">
                        Số lượng đã nhập kho
                      </th>
                      <th className="py-1.5 px-3 whitespace-nowrap border text-gray-700 font-medium">
                        Số lượng còn lại
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {store.listItemPO?.data?.purchaseOrderDetails.map((item, index) => (
                      <tr key={index}>
                        <td className="border py-1.5 px-3 text-center">
                          <input
                            hidden
                            className="outline-none"
                            type="text"
                            {...register(`poDetailQuantitys.${index}.po`)}
                            value={`${item.po}`}
                          />

                          <input
                            hidden
                            className="outline-none"
                            type="number"
                            min="0"
                            {...register(`poDetailQuantitys.${index}.poLine`)}
                            value={`${item.poLine}`}
                          />
                          {`${item.po}/${item.poLine}`}
                        </td>
                        <td className="border py-1.5 px-3 text-center ">
                          <input
                            hidden
                            className="outline-none w-min"
                            type="text"
                            {...register(`poDetailQuantitys.${index}.so`)}
                            value={`${item.so}`}
                          />
                          <input
                            hidden
                            className="outline-none w-min"
                            type="text"
                            {...register(`poDetailQuantitys.${index}.soLine`)}
                            value={`${item.soLine}`}
                          />
                          <input
                            hidden
                            className="outline-none w-min"
                            type="text"
                            {...register(`poDetailQuantitys.${index}.wbs`)}
                            value={`${item.wbs}`}
                          />
                          {/* nếu như không null hoặc không rỗng thì cho hiển thị */}
                          {`${item.so || item.so !== "" ? item.so : ""}${
                            item.soLine || item.soLine !== "" ? "" : "/" + item.soLine
                          }${item.wbs || item.wbs !== "" ? item.wbs : ""} `}
                        </td>
                        <td className="border py-1.5 px-3 text-center ">
                          <input
                            {...register(`poDetailQuantitys.${index}.quantity`)}
                            type="number"
                            min="0"
                            step="any"
                            className="border w-full outline-none px-3 py-1 text-end focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                            // lưu giá trị poDetailQuantitys
                            onChange={(e) => handleChangQuantity(e, index)}
                          />
                        </td>
                        <td className="border py-1.5 px-3 text-center">
                          <input
                            {...register(`poDetailQuantitys.${index}.unit`)}
                            type="hidden"
                            disabled
                            className="border w-full outline-none px-3 py-1 rounded"
                            value={item.unit}
                          />
                          {item.unit}
                        </td>
                        {/*format number => .toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') */}
                        <td className="border py-1.5 px-3 text-end">{`${item.poQuantity
                          .toFixed(3)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}</td>
                        <td className="border py-1.5 px-3 text-end">{`${item.quantityReceived
                          .toFixed(3)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}</td>
                        <td className="border py-1.5 px-3 text-end">{`${item.remainQuantity
                          .toFixed(3)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="w-full flex justify-end mb-2">
                <button
                  disabled={disabled}
                  type="submit"
                  className="flex items-center text-white bg-blue-700 rounded text-default px-2.5 py-1 text-center focus:outline-none hover:bg-blue-600 disabled:bg-gray-500"
                >
                  Tạo barcode
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* Thêm mới thành công thì show modal */}
        {store.dataGetBarcode && (
          <div
            // nếu show modal false thì hidden
            className={`w-full h-screen fixed top-0 right-0 z-[100] bg-modal ${showModal ? "" : "hidden"}`}
          >
            <div className="w-full h-full flex justify-center items-center">
              <div className="relative w-full sm:w-10/12 lg:w-2/3 xl:w-1/2 p-7 bg-white rounded mb-2 text-default shadow-xl overflow-y-auto">
                <FaTimes
                  // bắt sự kiện show modal
                  onClick={handleClosePrintmodal}
                  className="absolute right-5 top-5 text-lg hover:text-red-500 cursor-pointer"
                />

                {/* Top info */}
                <div className="flex flex-col mb-2">
                  <table className="border-collapse border border-gray-300 mt-4">
                    <tbody>
                      <tr>
                        <td className="border border-gray-300 px-4 py-2" rowSpan={3}>
                          <img src={logo} alt="Logo" />
                        </td>
                        <td className="border border-gray-300 px-1" rowSpan={3}>
                          <h1 className="text-4xl font-bold text-center">THẺ TREO NVL</h1>
                        </td>
                        <td className="border border-gray-300 px-1">Số hiệu:</td>
                        <td className="border border-gray-300 px-1">CL-QT07-F02</td>
                      </tr>
                      <tr>
                        <td className="border border-gray-300 px-1">Lần ban hành:</td>
                        <td className="border border-gray-300 px-1">02</td>
                      </tr>
                      <tr>
                        <td className="border border-gray-300 px-1">Ngày hiệu lực:</td>
                        <td className="border border-gray-300 px-1">11/07/2023</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* Main content */}
                <div className="block md:flex items-center pb-0 pt-0 min-h-[205px]">
                  <div className="relative mx-auto mb-3 md:ml-0 md:mb-0 md:mr-10 border-2 border-gray-200 p-2 w-fit">
                    <img
                      className="max-w-[150px] min-h-[150px] object-contain"
                      src={`${BASE_API_BARCODE}${store.dataGetBarcode.barcodePath}`}
                    />
                    <p className="whitespace-nowrap absolute bottom-[-25px] left-1/2 translate-x-[-50%] text-[12px]">
                      {store.dataGetBarcode.rawMaterialCardCode}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <div className="flex">
                      <span className="min-w-[100px] md:mr-10 block">Tên NCC:</span>
                      <span className="block">{store.dataGetBarcode.vendorName}</span>
                    </div>
                    <div className="flex">
                      <span className="min-w-[100px] md:mr-10 block">Số PO :</span>
                      <div>
                        {store.dataGetBarcode.poDetailResponses.map((item, index) => (
                          <span className="block" key={index}>
                            {item.po}/{item.poLine} (
                            {" " + item.quantity.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " " + item.unit}
                            )
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className="flex">
                      <span className="min-w-[100px] md:mr-10 block">Batch :</span>
                      <span className="block">{store.dataGetBarcode.inforReceived.batchNumber}</span>
                    </div>
                    <div className="flex">
                      <span className="min-w-[100px] md:mr-10 block">Mã hàng :</span>
                      <span className="block">{store.dataGetBarcode.productCode}</span>
                    </div>
                    <div className="flex">
                      <span className="min-w-[100px] md:mr-10 block">Tên hàng :</span>
                      <span className="block">{store.dataGetBarcode.productName}</span>
                    </div>
                    <div className="flex">
                      <span className="min-w-[100px] md:mr-10 block">LSXĐT :</span>
                      <span
                        className="block"
                        dangerouslySetInnerHTML={{
                          //   __html: store.dataGetBarcode.listLSXDT.join("</br>"),
                        __html: Array.from(new Set(store.dataGetBarcode.listLSXDT)).join("</br>"),
                        }}
                      ></span>
                    </div>
                    <div className="flex">
                      <span className="min-w-[100px] md:mr-10 block">SO/WBS :</span>
                      <span className="block">
                        {store.dataGetBarcode.poDetailResponses.map((item, index) => (
                          <span key={index} className="block">
                            {`${item.so || item.so !== "" ? item.so : ""}${
                              item.soLine || item.soLine !== "" ? "" : "/" + item.soLine
                            }${item.wbs || item.wbs !== "" ? item.wbs : ""} `}
                          </span>
                        ))}
                      </span>
                    </div>
                  </div>
                </div>
                {/* Bottom info */}
                <div className="flex flex-col mt-2">
                  <table className="border-collapse border border-gray-300">
                    <tbody>
                      <tr>
                        <td className="px-1 pt-2" style={{ width: "25%" }}>
                          Mã kiện:
                        </td>
                        <td className="px-1 pt-2" style={{ width: "25%" }}>
                          Số container:
                        </td>
                        <td className="px-1 pt-2" style={{ width: "34%" }}>
                          Ngày thực tế nhập kho:
                        </td>
                      </tr>
                      <tr>
                        <td className="px-1 pt-3 " colSpan={3}>
                          <div className="flex items-center">
                            <span className="pr-2">Tình trạng môi trường:</span>
                            <div className="inline-block flex items-center justify-center mr-3">
                              <BiCheckbox size={28} /> <span>FSC 100%</span>
                            </div>
                            <div className="inline-block flex items-center justify-center mr-3">
                              <BiCheckbox size={28} /> <span>FSC-MIX</span>
                            </div>
                            <div className="inline-block flex items-center justify-center">
                              <BiCheckbox size={28} /> <span>NON-FSC</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-1 pt-3 pb-2" colSpan={3}>
                          <div className="flex items-center">
                            <span className="pr-2">Chất lượng của NVL:</span>
                            <div className="inline-block flex items-center justify-center mr-3">
                              <BiCheckbox size={28} /> <span>Đạt</span>
                            </div>
                            <div className="inline-block flex items-center justify-center mr-3">
                              <BiCheckbox size={28} /> <span>Không đạt</span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="hidden">
                  <ContentToPrint
                    ref={printRef}
                    barcodePath={store.dataGetBarcode.barcodePath}
                    vendorName={store.dataGetBarcode.vendorName}
                    productCode={store.dataGetBarcode.productCode}
                    productName={store.dataGetBarcode.productName}
                    sowps={store.dataGetBarcode.poDetailResponses}
                    poCode={store.dataGetBarcode.poDetailResponses}
                    listLSXDT={store.dataGetBarcode.listLSXDT}
                    batch={store.dataGetBarcode.inforReceived.batchNumber}
                    rawMaterialCardId={store.dataGetBarcode.rawMaterialCardCode}
                  />
                </div>
                <ReactToPrint
                  trigger={() => (
                    <div className="flex justify-end pt-4">
                      <button className="flex items-center text-white bg-blue-700 rounded text-default px-2.5 py-1 text-center focus:outline-none hover:bg-blue-600">
                        In barcode
                      </button>
                    </div>
                  )}
                  content={() => printRef.current}
                  onAfterPrint={handlePrint}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  });
}
