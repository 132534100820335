import { IServices } from 'services/services';
import { LayoutHOC } from 'base/ui/layout/layout';
import { createHomePage } from './home/create';
import { createLoginPage } from './login/create';
import { createPurchaseOrderPage } from './purchaseOrder/create';
import { createPODetailPage } from './purchaseOrderDetail/create';
import { createPrintBarcodePage } from './printBarCode/create';
import { createBarcodeHistoryPage } from './barcodeHistory/create';
import { createHistoryDetailPage } from './historyDetail/create';
import { createChangePasswordPage } from './changePassword/create';

export function createPages(layoutHOC: LayoutHOC, services: IServices) {
    return {
        HomePage: createHomePage(layoutHOC),
        LoginPage: createLoginPage(layoutHOC, services, services),
        PurchaseOrderPage: createPurchaseOrderPage(layoutHOC),
        PODetailsPage: createPODetailPage(layoutHOC),
        PrintBarcodePage: createPrintBarcodePage(layoutHOC, services),
        BarcodeHistoryPage: createBarcodeHistoryPage(layoutHOC),
        DeliveryHistoryDetailPage: createHistoryDetailPage(layoutHOC),
        ChangePasswordPage: createChangePasswordPage(layoutHOC),
    };
}
