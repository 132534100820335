import { observer } from 'mobx-react';
import { useState, useEffect, useRef } from 'react';
import { IoArrowBackCircle } from 'react-icons/io5';
import { deliveryHistoryStore } from 'stores/root_store';

export function createHistoryDetail() {
    return observer(() => {
        return (
            <div className="w-full p-5 min-h-screen">
                {/* back lại trnag trước */}
                <span
                    onClick={() => window.history.back()}
                    className="flex items-center text-xs text-primary cursor-pointer mb-3 font-medium"
                >
                    <IoArrowBackCircle className="mr-2 text-base text-gray-500" />
                    Quay lại
                </span>
                {/* tiêu đề trang */}
                <div className="w-full px-5 py-2 bg-white rounded rounded-b-none flex border border-t-2 border-t-sky-700 border-neutral-300 bg-gray-100">
                    <h3 className="text-md font-bold text-gray-800 uppercase">barcode pallet NVL</h3>
                </div>

                <div className="w-full p-5 bg-white rounded mb-2 rounded-t-none border border-t-0 border-neutral-300">
                    <div className="w-full">
                        <div className="w-full sm:block lg:flex">
                            {/* Mã nhà cung cấp */}
                            <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-sm sm:pr-0 lg:pr-10">
                                <label className="font-medium text-gray-700">Mã NCC</label>
                                {/* hiển thị mã ncc */}
                                <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100"></span>
                            </div>
                            {/* Tên nhà cung cấp */}
                            <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-sm">
                                <label className="font-medium text-gray-700">Tên NCC</label>
                                {/* Hiển thị tên ncc */}
                                <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100"></span>
                            </div>
                        </div>
                        <div className="w-full sm:block lg:flex">
                            {/* PO / PO line */}
                            <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-sm sm:pr-0 lg:pr-10">
                                <label className="font-medium text-gray-700">PO/PO Line</label>
                                <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100"></span>
                            </div>
                        </div>
                        <div className="w-full sm:block lg:flex">
                            {/* Mã hàng  */}
                            <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-sm sm:pr-0 lg:pr-10">
                                <label className="font-medium text-gray-700">Mã hàng</label>
                                <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100"></span>
                            </div>
                            {/* Tên hàng */}
                            <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-sm">
                                <label className="font-medium text-gray-700">Tên hàng</label>
                                <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100"></span>
                            </div>
                        </div>
                        <div className="w-full sm:block lg:flex">
                            {/* SO / SO line */}
                            <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-sm sm:pr-0 lg:pr-10">
                                <label className="font-medium text-gray-700">SO/SO Line</label>
                                <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100"></span>
                            </div>
                            {/* WBS */}
                            <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-sm">
                                <label className="font-medium text-gray-700">WBS</label>
                                <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100"></span>
                            </div>
                        </div>

                        <div className="w-full sm:block lg:flex">
                            {/* số lượng đặt hàng */}
                            <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-sm sm:pr-0 lg:pr-10">
                                <label className="font-medium text-gray-700">Số lượng đặt hàng</label>
                                {/* format number => .toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') */}
                                <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100"></span>
                            </div>
                            {/* đơn vị tính của số lượng đặt hàng */}
                            <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-sm">
                                <label className="font-medium text-gray-700">Đơn vị tính</label>
                                <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100"></span>
                            </div>
                        </div>
                        <div className="w-full sm:block lg:flex">
                            {/* Số lượng đã nhận (số lượng nhập kho) */}
                            <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-sm sm:pr-0 lg:pr-10">
                                <label className="font-medium text-gray-700">Số lượng đã nhập kho</label>
                                {/* Hiển thị số lượng đã nhận */}
                                <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100"></span>
                            </div>
                            {/* Đơn vị tính của số lượng nhập kho */}
                            <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-sm">
                                <label className="font-medium text-gray-700">Đơn vị tính</label>
                                <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100"></span>
                            </div>
                        </div>
                        <div className="w-full sm:block lg:flex">
                            {/* Số lượng */}
                            <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-sm sm:pr-0 lg:pr-10">
                                <label className="font-medium text-gray-700">Số lượng</label>
                                {/* Hiển thị Số lượng */}
                                <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100"></span>
                            </div>
                            {/* Đơn vị tính của Số lượng */}
                            <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-sm">
                                <label className="font-medium text-gray-700">Đơn vị tính</label>
                                <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100"></span>
                            </div>
                        </div>
                        <div className="w-full sm:block lg:flex">
                            {/* Trọng lượng */}
                            <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-sm sm:pr-0 lg:pr-10">
                                <label className="font-medium text-gray-700">Trọng lượng</label>
                                {/* Hiển thị Trọng lượng */}
                                <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100"></span>
                            </div>
                            {/* Đơn vị tính của Trọng lượng */}
                            <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-sm">
                                <label className="font-medium text-gray-700">Đơn vị tính</label>
                                <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100"></span>
                            </div>
                        </div>
                        <div className="w-full sm:block lg:flex">
                            {/*Số lượng quy đổi */}
                            <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-sm sm:pr-0 lg:pr-10">
                                <label className="font-medium text-gray-700">Số lượng quy đổi</label>
                                {/* Hiển thị Số lượng quy đổi */}
                                <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100"></span>
                            </div>
                            {/* Đơn vị tính của Số lượng quy đổi */}
                            <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-sm">
                                <label className="font-medium text-gray-700">Đơn vị tính</label>
                                <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100"></span>
                            </div>
                        </div>
                        {/* Quy cách */}
                        <div className="w-full sm:block lg:flex">
                            <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-sm sm:pr-0 lg:pr-10">
                                <label className="font-medium text-gray-700">Quy cách</label>
                                <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100"></span>
                            </div>
                            {/* Ngày sản xuất */}
                            <div className="mb-3 sm:w-full md:w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 text-sm">
                                <label className="font-medium text-gray-700">Ngày sản xuất</label>
                                <span className="block min-h-[34px] flex-1 py-1.5 px-3 mt-1 border bg-gray-100"></span>
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <button className="flex items-center text-white bg-blue-700 rounded text-sm px-2.5 py-1 text-center focus:outline-none hover:bg-blue-600">
                                In barcode
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    });
}
