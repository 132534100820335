import { observable, runInAction } from 'mobx';
import { IBarcodeServices, ListItemPORespone, BarcodeResponse, BarcodeDetails } from 'services/barcode_service';

export type BarcodeStore = {
    // danh sách
    listItemPO: ListItemPORespone | undefined;

    dataSaveBarcode: {
        isSuccess: boolean;
        message: string;
        data: string;
    };
    dataGetBarcode: BarcodeDetails | undefined;
};

export interface IBarcodePresenter {
    createStore(): BarcodeStore;
    getListItemPO(store: BarcodeStore, data: Array<string>): Promise<void>;
    saveBarcode(store: BarcodeStore, data: object): Promise<void>;
    getBarcode(store: BarcodeStore, RawMaterialCardId: string): Promise<void>;
}

export function createBarcodePresenter({ barcodeServices }: { barcodeServices: IBarcodeServices }): IBarcodePresenter {
    return {
        createStore: (): BarcodeStore =>
            observable({
                listItemPO: undefined,
                dataSaveBarcode: { isSuccess: false, message: '', data: '' },
                dataGetBarcode: undefined,
            }),
        getListItemPO: async (store: BarcodeStore, data: Array<string>) => {
            try {
                const result = await barcodeServices.getListItemPO(data);
                runInAction(() => {
                    store.listItemPO = result;
                });
            } catch (e) {}
        },
        saveBarcode: async (store: BarcodeStore, data: object) => {
            try {
                const result = await barcodeServices.saveBarcode(data);
                runInAction(() => {
                    store.dataSaveBarcode.isSuccess = result.isSuccess;
                    store.dataSaveBarcode.data = result.data;
                    store.dataSaveBarcode.message = result.message;
                });
            } catch (e) {}
        },
        getBarcode: async (store: BarcodeStore, rawMaterialCardId: string) => {
            try {
                const result = await barcodeServices.getBarcode(rawMaterialCardId);
                runInAction(() => {
                    store.dataGetBarcode = result;
                });
            } catch (e) {}
        },
    };
}
