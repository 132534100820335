import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { ROUTES } from 'base/routes/routes';
import logo from 'assets/logo.png';
import { HiMenu } from 'react-icons/hi';
import { User } from 'services/user_services';
import MenuItem from 'base/ui/layout/sideBar/menuItem';

export const Sidebar = React.memo(() => {
    const [open, setOpen] = useState(false);
    const data = JSON.parse(localStorage.getItem('user_session') || '{}') as User;
    const parentMenu = data.webPermission.menuModel;
    const childMenu = data.webPermission.pageModel;

    const handleShowMenu = () => {
        setOpen(!open);
    };

    return (
        <div
            className={`absolute h-full sm:h-auto sm:relative bg-gray-800 min-h-screen pt-1.5 ${
                open ? 'sm:block w-72' : 'w-0 sm:w-14'
            } duration-300`}
        >
            <HiMenu
                className="absolute text-white text-lg -right-9 top-4 cursor-pointer hidden sm:block"
                onClick={handleShowMenu}
            />
            <div className={`inline-flex pl-2`}>
                <Link to="/" className={`w-10 h-10 rounded-lg float-left mr-2 z-[1] overflow-hidden hidden sm:block`}>
                    <img src={logo} />
                </Link>
                <HiMenu
                    onClick={handleShowMenu}
                    className="mr-3 text-white text-lg z-[1] my-auto sm:hidden cursor-pointer"
                />
                <Link
                    to="/"
                    className={`h-10 leading-10 w-max text-white origin-left font-medium text-base duration-300 z-[1] m-auto select-none ${
                        !open && 'sm:scale-0'
                    }`}
                >
                    iMES System
                </Link>
            </div>
            <span className="absolute block min-h-[50px] bg-primary w-full top-0 left-0 "></span>
            {/* <div className="flex items-center rounded-md bg-white-200"></div> */}
            <ul
                onClick={() => (open ? setOpen(true) : setOpen(!open))}
                className={`pt-2 ${open ? '' : 'hidden sm:block'}`}
            >
                {parentMenu
                    ? parentMenu.map((parentItem: any, index: any) => (
                          <MenuItem
                              key={index}
                              open={open}
                              menuId={parentItem.menuId}
                              menuName={parentItem.menuName}
                              icon={parentItem.icon}
                              childMenu={childMenu}
                          />
                      ))
                    : ''}
            </ul>
        </div>
    );
});
