import { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';

import {
    deliveryHistoryPresenter,
    deliveryHistoryStore,
    purchaseOrderPresenter,
    purchaseOrderStore,
} from 'stores/root_store';

import { HiSearch } from 'react-icons/hi';
import Moment from 'react-moment';
import { FaCheck, FaRegEye, FaTimes } from 'react-icons/fa';
import { PAGE_SIZE_DEFAULT } from 'utils/constants';
import Pagination from 'base/ui/components/Pagination/pagination';
import { TbLoaderQuarter } from 'react-icons/tb';
import { debounce } from '@mui/material';
import { useNavigate } from 'react-router-dom';

type SearchDeliveryHistory = {
    paging: {
        draw: number;
        start: number;
        length: number;
    };
    productCode: string;
    purchaseOrderCode: string;
    //
    vendorCode: string;
    //
    so: string;
    //
    wbs: string;
    fromDate_DocumentDate: Date;
    toDate_DocumentDate: Date;
    fromDate_CreateTime: Date;
    toDate_CreateTime: Date;
    //
    isReceive: boolean;
    //
    actived: string;
};

export function createBarcodeHistory() {
    return observer(() => {
        //  =================== Route =======================//
        // chuển trang
        const navigate = useNavigate();
        // =================== = =======================//
        // kiểm tra nhà cung cấp hay hay nhân viên
        const [isVendor, setIsVendor] = useState(false);

        // ============== Phân trang =================//
        // Số lượng items trên 1 trang
        const [pageSize, setPageSize] = useState(PAGE_SIZE_DEFAULT);
        // Tổng số trang
        const [pageCount, setPageCount] = useState(0);
        // Bắt đầu từ item (start)
        const [currentPage, setcurrentPage] = useState(0);
        // Trang được select -> trang hiện tại
        // Mặc định trang là trang đầu tiên (trang 1 select = 0)
        // Set -1 để không bị báo lỗi: (react-paginate): The forcePage prop provided is greater than the maximum page index from pageCount prop (0 > -1).
        const [pageSelected, setPageSelected] = useState(-1);
        // ==================================================//

        // ============== DeliveryHistory table =================//
        // Tiêu đề các cột của bảng lịch sử giao hàng
        const column = [
            { columnName: 'STT', width: 'w-[130px]', align: 'text-center', visible: true },
            {
                columnName: 'Thời gian tạo barcode',
                align: 'text-center',
                visible: true,
            },
            {
                columnName: 'Số PO',
                align: 'text-center',
                visible: true,
            },
            {
                columnName: 'Số SO',
                align: 'text-center',
                visible: true,
            },
            {
                columnName: 'WBS',
                align: 'text-center',
                visible: true,
            },
            {
                columnName: 'Plant',
                align: 'text-center',
                visible: true,
            },
            {
                columnName: 'Ngày chứng từ',
                align: 'text-center',
                visible: true,
            },
            {
                columnName: 'Mã nguyên vật liệu',
                align: 'text-center',
                visible: true,
            },
            {
                columnName: 'Tên nguyên vật liệu',
                align: 'text-center',
                visible: true,
            },
            {
                columnName: 'Số lượng đặt hàng',
                align: 'text-center',
                visible: true,
            },
            {
                columnName: 'Số lượng đã giao',
                align: 'text-center',
                visible: true,
            },
            {
                columnName: 'Số lượng giao hàng',
                align: 'text-center',
                visible: true,
            },
            {
                columnName: 'ĐVT',
                align: 'text-center',
                visible: true,
            },
            {
                columnName: 'Mã NCC',
                align: 'text-center',
                visible: isVendor ? false : true,
            },
            {
                columnName: 'Tên NCC',
                align: 'text-center',
                visible: isVendor ? false : true,
            },
            {
                columnName: 'Tình trạng giao hàng',
                align: 'text-center',
                visible: true,
            },
            {
                columnName: 'Thời gian nhập kho',
                align: 'text-center',
                visible: true,
            },
            {
                columnName: 'QC',
                align: 'text-center',
                visible: isVendor ? false : true,
            },
            {
                columnName: 'Trạng thái',
                align: 'text-center',
                visible: isVendor ? false : true,
            },
            {
                columnName: 'Chức năng',
                align: 'text-center',
                visible: true,
            },
        ];
        // =====================================================//

        // ============== Loadding =============================//
        const [loading, setLoading] = useState(true);
        // =====================================================//

        // ===================== Dữ liệu form tìm kiếm ===========//
        // Mã nguyên vật liệu
        const [productCode, setProductCode] = useState<string>('');
        // Mã Purchase order
        const [purchaseOrderCode, setPurchaseOrderCode] = useState('');
        // Mã nhà cung cấp
        const [vendorCode, setVendorCode] = useState('');
        const [so, setSO] = useState('');
        const [wbs, setWBS] = useState('');
        // Ngày chứng từ
        const [fromDate_DocumentDate, setFromDate_DocumentDate] = useState<any>(null);
        const [toDate_DocumentDate, setToDate_DocumentDate] = useState<Date>();
        // Ngày tạo barcode
        const [fromDate_CreateTime, setFromDate_CreateTime] = useState<Date>();
        const [toDate_CreateTime, setToDate_CreateTime] = useState<Date>();
        // Tình trạng giao hàng
        const [isReceive, setIsReceive] = useState(null);
        // Trạng thái
        const [active, setActive] = useState<boolean | null>(null);
        // ======================================================//

        // =============== Search vendor =========================//
        // lấy ref của popup search
        const popupRef = useRef<any>(null);
        // show hide tooltip
        const [show, setShow] = useState(false);
        // dữ liệu search input
        const [vendorSearchValue, setVendorSearchValue] = useState(''); //dùng để hiển thị trên ô ipput, cả mã vendor và tên (vendorDisplay)
        // search loading // dùng để loading lúc search
        // phần loading này chỉ dùng cho search
        const [searchLoading, setSearchLoading] = useState(false);
        // =======================================================//

        // =============== Kiểm tra role =========================//
        useEffect(() => {
            setLoading(true);
            // kiểm tra vendor
            const userSession = JSON.parse(localStorage.getItem('user_session') || '{}');
            const checkVendor = () => {
                if (userSession.role === 'NCC') {
                    setIsVendor(true);
                }
            };

            checkVendor();
        }, []);
        // =======================================================//

        // =============== Validate =========================//
        // Sử dụng useForm để lấy value từ form và validate dữ liệu
        const {
            register,
            handleSubmit,
            formState: { errors },
        } = useForm<SearchDeliveryHistory>();
        // =============== ====================================== ======================

        // =============== Xử lý phân trang =========================//
        //xử lý thay đổi page size
        useEffect(() => {
            // cho loading khi gọi api
            setLoading(true);
            // call api thay đổi số item trên 1 trang
            const pagination = async () => {
                const result = await deliveryHistoryPresenter.searchDeliveryHistory(deliveryHistoryStore, {
                    paging: {
                        draw: 1,
                        start: 0,
                        length: pageSize,
                    },
                    vendorCode: vendorCode,
                });

                if (result) {
                    // số trang bằng tổng số item chia số item trên 1 trang :pageCount = recordsFiltered / pageSize (recordsFiltered lưu trong store =>lấy từ api)
                    setPageCount(Math.ceil(deliveryHistoryStore.listDeliveryHistory.recordsFiltered / pageSize));
                }
                // nếu page size thay đổi thì chuyển về trang đầu tiên
                setPageSelected(0);
                // ngưng loading khi có dữ liệu trả về
                setLoading(false);
            };
            pagination();
        }, [pageSize]);

        // chuyển trang (page index)
        useEffect(() => {
            // cho loading khi gọi api
            setLoading(true);
            // thay đổi trang
            const pagination = async () => {
                await deliveryHistoryPresenter.searchDeliveryHistory(deliveryHistoryStore, {
                    paging: {
                        draw: 1,
                        start: currentPage,
                        length: pageSize,
                    },
                    vendorCode: vendorCode,
                });
                // ngưng loading khi có dữ liệu trả về
                setLoading(false);
            };
            pagination();
        }, [currentPage]);

        //  sự kiện click chuyển trang
        const handlePageClick = (data: any) => {
            setPageSelected(data.value);
            // lấy từ item số n (start)
            // trang 1 bắt đầu từ item thứ 0 ,
            // trang 2 bắt đầu từ item thứ (số trang -1) * (số item trên trang), vd pageSize = 10 => currentPage = (2-1)*10=10 -> start =10 // api trả về từ item thứ 11
            // data.selected để lấy ra số trang
            // vd trang = 1 thì data.selected = 0
            setcurrentPage(data.selected * pageSize);
        };
        // lấy value của select để set page size (thay đổi số lượng item trên trang)
        const handleSelectPageSize = (e: React.ChangeEvent<HTMLSelectElement>) => {
            setPageSize(parseInt(e.target.value));
        };
        // ========================================================================================//

        // ========================== Submit Form tìm kiếm và xử lý tìm kiếm =======================//
        const submitForm = async (data: SearchDeliveryHistory) => {
            // cho loading
            setLoading(true);
            // gán các giá trị vào state để phân trang
            setProductCode(data.productCode);
            setPurchaseOrderCode(data.purchaseOrderCode);
            setSO(data.so);
            setWBS(data.wbs);
            setFromDate_DocumentDate(data.fromDate_DocumentDate);
            setToDate_DocumentDate(data.toDate_DocumentDate);
            setFromDate_CreateTime(data.fromDate_CreateTime);
            setToDate_CreateTime(data.toDate_CreateTime);
            setActive(JSON.parse(data.actived.toLocaleLowerCase()));
            // lấy dữ liệu từ form và gọi api
            const result = await deliveryHistoryPresenter.searchDeliveryHistory(deliveryHistoryStore, {
                paging: {
                    draw: 1,
                    start: 0,
                    length: pageSize,
                },
                productCode: data.productCode,
                purchaseOrderCode: data.purchaseOrderCode,
                vendorCode: vendorCode,
                so: data.so,
                wbs: data.wbs,
                fromDate_DocumentDate: data.fromDate_DocumentDate,
                toDate_DocumentDate: data.toDate_DocumentDate,
                fromDate_CreateTime: data.fromDate_CreateTime,
                toDate_CreateTime: data.toDate_CreateTime,
                // isReceive:
                // value kiểu string cần đưa về kiểu boolean hoặc null (true: hoạt động, false: đã hủy, null: tất cả)
                actived: JSON.parse(data.actived.toLocaleLowerCase()),
            });
            if (result) {
                setcurrentPage(0);
                // số trang = tổng số items (records) / số items trên trang
                setPageCount(Math.ceil(deliveryHistoryStore.listDeliveryHistory.recordsFiltered / pageSize));
                setLoading(false);
            }
        };
        // ========================================================================================//

        // =================================== Xử lý tìm kiếm vendor ==============================//
        // theo dỗi sự thay đổi của ref
        // thêm sự kiện mousedown trên popup nếu click ra ngoài thì setshow = false
        useEffect(() => {
            const handleClickAway = (e: any) => {
                if (popupRef.current && !popupRef.current.contains(e.target)) {
                    setShow(false);
                }
            };
            document.addEventListener('mousedown', handleClickAway);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickAway);
            };
        }, [popupRef]);
        // sử dụng debounce để tránh gửi nhiều request, nếu ngưng gõ 1s thì call api
        const debounceDropDown = useCallback(
            debounce(async (nextValue) => {
                await purchaseOrderPresenter.searchVendor(purchaseOrderStore, nextValue);
                setSearchLoading(false);
            }, 1000),
            [],
        );
        // xử lý searchVendor
        //  sự kiện onchange trên input search vendor
        const handlSearchVendor = async (e: React.ChangeEvent<HTMLInputElement>) => {
            // cho loading khi tìm kiếm
            // ngưng loading khi có kết quả tìm kiếm
            setSearchLoading(true);
            setVendorSearchValue(e.target.value);
            // rootStore.purchaseOrderPresenter.searchVendor(rootStore.purchaseOrderStore, e.target.value);
            await debounceDropDown(e.target.value);
        };
        // sự kiện click chọn vendor sau khi tìm kiếm
        const handleSelectVendor = (vendorCode: string, vendorDisplay: string) => {
            // hiển thị mã và tên vendor lên ô input (gán value cho input search)
            setVendorSearchValue(vendorDisplay);
            setVendorCode(vendorCode); // lưu lại mã vendor để tìm kiếm po theo vendor
            setShow(false);
        };
        // xóa value trên input search vendor và mã vendor (vendorCode)
        const handlClearSearchValue = () => {
            // xóa text trên input -> set state rỗng
            setVendorSearchValue('');
            setVendorCode('');
        };
        // ========================================================================================//

        // ========================== Xem chi tiết ================================================//
        const handleShowDetail = (rawMaterialCardId: string) => {
            navigate(`${rawMaterialCardId}`);
        };
        // ========================================================================================//

        return (
            <div className="w-full py-3 px-5">
                {/* ===============TIÊU ĐỀ TRANG ======================== */}
                <h3 className="text-md font-bold text-gray-800 mb-2 uppercase">Lịch sử giao hàng</h3>
                {/* =============== FORM TÌM KIẾM ======================  */}
                {/* Form nhập tìm kiếm */}
                <div className="w-full bg-white py-3 flex rounded mb-2 border border-neutral-300">
                    <form onSubmit={handleSubmit(submitForm)} className="w-full px-3 py-2 ">
                        <div className="w-full flex flex-col md:flex-col lg:flex-row xl:flex-row sm:flex-col ">
                            {/* Tìm kiếm theo mã nguyên vật liệu */}
                            <div className="lg:w-1/2 md:w-full sm:w-full">
                                <div className="w-full flex flex-col sm:flex-row lg:px-20 lg:pr-3 px-3 mb-3 sm:items-center">
                                    <label className="min-w-[150px] font-medium text-sm text-gray-700 sm:text-end">
                                        Mã NVL
                                    </label>
                                    {/* Nhập mã nguyên vật liệu */}
                                    <input
                                        {...register('productCode')}
                                        type="text"
                                        className="text-gray-700 border border-gray-300 sm:ml-5 px-3 py-1.5 text-sm flex-1 focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                                    />
                                </div>
                            </div>
                            {/* Tìm kiếm theo SO  */}
                            {/* yêu cầu: Search cả mã và tên */}
                            <div className="lg:w-1/2 md:w-full sm:w-full">
                                <div className="w-full flex flex-col sm:flex-row lg:px-20 lg:pl-3 px-3 mb-3 sm:items-center">
                                    <label className="min-w-[150px] font-medium text-sm text-gray-700 sm:text-end">
                                        SO
                                    </label>
                                    {/* Nhập mã SO / Tên so */}
                                    <input
                                        {...register('so')}
                                        className="text-gray-700 border border-gray-300 sm:ml-5 px-3 py-1.5 text-sm flex-1 focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row xl:flex-row sm:flex-col ">
                            {/* Tìm kiếm theo mã PO */}
                            <div className="lg:w-1/2 md:w-full sm:w-full">
                                <div className="w-full flex flex-col sm:flex-row lg:px-20 lg:pr-3 px-3 mb-3 sm:items-center">
                                    <label className="min-w-[150px] font-medium text-sm text-gray-700 sm:text-end">
                                        Số PO
                                    </label>
                                    {/* input nhập mã PO */}
                                    <input
                                        {...register('purchaseOrderCode')}
                                        className="text-gray-700 border border-gray-300 sm:ml-5 px-3 py-1.5 text-sm flex-1 focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                                    />
                                </div>
                            </div>
                            {/* Tìm kiếm theo WBS  */}
                            <div className="lg:w-1/2 md:w-full sm:w-full">
                                <div className="w-full flex flex-col sm:flex-row lg:px-20 lg:pl-3 px-3 mb-3 sm:items-center">
                                    <label className="min-w-[150px] font-medium text-sm text-gray-700 sm:text-end">
                                        WBS
                                    </label>
                                    {/* input nhập wbs */}
                                    <input
                                        {...register('wbs')}
                                        className="text-gray-700 border border-gray-300 sm:ml-5 px-3 py-1.5 text-sm flex-1 focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row xl:flex-row sm:flex-col ">
                            {/* Tìm kiếm theo mã PO */}
                            {/* cần phải tìm kiếm mã nhà cung cấp khi nhập vào input */}
                            <div className="lg:w-1/2 md:w-full sm:w-full">
                                <div className="w-full flex flex-col sm:flex-row lg:px-20 lg:pr-3 px-3 mb-3 sm:items-center">
                                    <label className="min-w-[150px] font-medium text-sm text-gray-700 sm:text-end">
                                        NCC
                                    </label>
                                    <div className="relative flex items-center w-full sm:ml-5">
                                        <input
                                            type="text"
                                            value={vendorSearchValue}
                                            onClick={() => setShow(true)}
                                            onChange={handlSearchVendor}
                                            className={`w-full text-gray-700 border border-gray-300 rounded-r-none px-3 py-1.5 text-sm flex-1 focus-visible:outline-none focus:border-blue-500 caret-blue-600 disabled:bg-gray-100`}
                                        />
                                        {/* nếu có dữ liệu thì hiện popup lên cho chọn vendor */}
                                        {show && (
                                            <ul
                                                ref={popupRef}
                                                className={`absolute w-full max-h-[200px] overflow-y-auto bg-white top-9 shadow-md shadow-gray-300 text-sm`}
                                            >
                                                {/* kiểm tra có dữ liệu và hiện item  */}
                                                {purchaseOrderStore.listVendor &&
                                                    purchaseOrderStore.listVendor.data.map((vendor) => (
                                                        <li
                                                            onClick={() =>
                                                                handleSelectVendor(
                                                                    vendor.vendorCode,
                                                                    vendor.vendorDisplay,
                                                                )
                                                            }
                                                            key={vendor.vendorCode}
                                                            className="w-full px-3 py-2 bg-gray-50 cursor-pointer hover:bg-gray-200"
                                                        >
                                                            {vendor.vendorDisplay}
                                                        </li>
                                                    ))}
                                            </ul>
                                        )}
                                        <span
                                            onClick={handlClearSearchValue}
                                            className="min-w-[34px] min-h-[34px] flex justify-center items-center border border-l-0 border-gray-300 cursor-pointer hover:bg-red-500 hover:text-white hover:border-red-500 text-xs"
                                        >
                                            {searchLoading ? <TbLoaderQuarter className="animate-spin" /> : <FaTimes />}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Ngày chứng từ */}
                        <h3 className="font-medium text-sm lg:px-32 lg:pr-5 sm:px-16 px-3 mb-3 text-gray-700">
                            Ngày chứng từ
                        </h3>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row xl:flex-row sm:flex-col mb-3">
                            <div className="lg:w-1/2 md:w-full sm:w-full lg:mb-0 mb-3 flex flex-col sm:flex-row lg:px-20 lg:pr-3 px-3 sm:items-center">
                                <label className="min-w-[150px] font-medium text-sm text-gray-700 sm:text-end">
                                    Từ ngày
                                </label>
                                {/* input nhập ngày chứng từ - từ ngày */}
                                <input
                                    {...register('fromDate_DocumentDate')}
                                    type="date"
                                    className="text-gray-700 border border-gray-300 sm:ml-5 px-3 py-1.5 text-sm flex-1 focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                                />
                            </div>
                            <div className="lg:w-1/2 md:w-full sm:w-full flex flex-col sm:flex-row lg:px-20 lg:pl-3 px-3 sm:items-center">
                                <label className="min-w-[150px] font-medium text-sm text-gray-700 sm:text-end">
                                    Đến ngày
                                </label>
                                {/* input nhập ngày chứng từ - đến ngày */}
                                <input
                                    {...register('toDate_DocumentDate')}
                                    type="date"
                                    className="text-gray-700 border border-gray-300 sm:ml-5 px-3 py-1.5 text-sm flex-1 focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                                />
                            </div>
                        </div>
                        {/* Ngày tạo barcode */}
                        <h3 className="font-medium text-sm lg:px-28 lg:pr-5 px-3 mb-3 text-gray-700 sm:px-12">
                            Ngày tạo barcode
                        </h3>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row xl:flex-row sm:flex-col mb-3">
                            <div className="lg:w-1/2 md:w-full sm:w-full lg:mb-0 mb-3 flex flex-col sm:flex-row lg:px-20 lg:pr-3 px-3 sm:items-center">
                                <label className="min-w-[150px] font-medium text-sm text-gray-700 sm:text-end">
                                    Từ ngày
                                </label>
                                {/* input nhập ngày tạo barcode - từ ngày */}
                                <input
                                    {...register('fromDate_CreateTime')}
                                    type="date"
                                    className="text-gray-700 border border-gray-300 sm:ml-5 px-3 py-1.5 text-sm flex-1 focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                                />
                            </div>
                            <div className="lg:w-1/2 md:w-full sm:w-full flex flex-col sm:flex-row lg:px-20 lg:pl-3 px-3 sm:items-center">
                                <label className="min-w-[150px] font-medium text-sm text-gray-700 sm:text-end">
                                    Đến ngày
                                </label>
                                {/* input nhập ngày tạo barcode - đến ngày */}
                                <input
                                    {...register('toDate_CreateTime')}
                                    type="date"
                                    className="text-gray-700 border border-gray-300 sm:ml-5 px-3 py-1.5 text-sm flex-1 focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                                />
                            </div>
                        </div>
                        <div className="w-full flex flex-col md:flex-col lg:flex-row xl:flex-row sm:flex-col mb-3">
                            {/* Tình trangj giao hàng */}
                            <div className="lg:w-1/2 md:w-full sm:w-full lg:mb-0 mb-3 flex flex-col sm:flex-row lg:px-20 lg:pr-3 px-3 sm:items-center">
                                <label className="min-w-[150px] font-medium text-sm text-gray-700 sm:text-end">
                                    Tình trạng giao hàng
                                </label>
                                <select className="text-gray-700 border border-gray-300 sm:ml-5 px-3 py-1.5 text-sm flex-1 focus-visible:outline-none focus:border-blue-500 caret-blue-600">
                                    <option value="null">-- Tất cả --</option>
                                    <option value="true">Đang giao</option>
                                    <option value="false">Đã giao</option>
                                </select>
                            </div>
                            {/* trạng thái */}
                            <div className="lg:w-1/2 md:w-full sm:w-full flex flex-col sm:flex-row lg:px-20 lg:pl-3 px-3 sm:items-center">
                                <label className="min-w-[150px] font-medium text-sm text-gray-700 sm:text-end">
                                    Trạng thái
                                </label>
                                <select
                                    {...register('actived')}
                                    className="text-gray-700 border border-gray-300 sm:ml-5 px-3 py-1.5 text-sm flex-1 focus-visible:outline-none focus:border-blue-500 caret-blue-600"
                                >
                                    <option value="null">-- Tất cả --</option>
                                    <option value="true">Hoạt động</option>
                                    <option value="false">Đã hủy</option>
                                </select>
                            </div>
                        </div>
                        <div className="w-full flex justify-center">
                            <button
                                type="submit"
                                className={`${
                                    loading && '!bg-blue-500'
                                } flex items-center text-white bg-blue-700 rounded text-sm px-2.5 py-1 text-center focus:outline-none hover:bg-blue-600`}
                            >
                                {loading ? (
                                    <>
                                        <TbLoaderQuarter className="animate-spin" />
                                        Loading...
                                    </>
                                ) : (
                                    <>
                                        <HiSearch className="font-bold text-lg" />
                                        Tìm kiếm
                                    </>
                                )}
                            </button>
                        </div>
                    </form>
                </div>

                <div className="w-full bg-white py-3 px-5 rounded border border-neutral-300">
                    {/*=========== Phân trang =======================*/}
                    <Pagination
                        pageCount={pageCount}
                        forcePage={pageSelected}
                        onPageChange={handlePageClick}
                        onPageSizeChange={handleSelectPageSize}
                    />

                    {/* =========== Table PO ===============*/}
                    <div className={`overflow-x-auto my-3`}>
                        <table className="w-full table-auto border-collapse space-collapse mb-5">
                            <thead className="font-medium text-sm text-gray-700 bg-gray-100">
                                <tr>
                                    {column.map((item, index) => {
                                        if (item.visible) {
                                            return (
                                                <th
                                                    key={index}
                                                    scope="col"
                                                    className={`${
                                                        item.visible ? '' : 'hidden'
                                                    } py-2 px-5 border whitespace-nowrap text-left ${item.align} ${
                                                        item.width
                                                    }`}
                                                >
                                                    {item.columnName}
                                                </th>
                                            );
                                        }
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {deliveryHistoryStore.listDeliveryHistory.data.map((row, index) => (
                                    <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}>
                                        {/* số thứ tự */}
                                        <td className="py-1.5 px-2 border text-sm whitespace-nowrap text-center w-min">
                                            {row.stt}
                                        </td>
                                        {/* Thời gian tạo barcode */}
                                        <td className="py-1.5 px-2 border text-sm whitespace-nowrap">
                                            <Moment format="DD/MM/YYYY (hh:mm:ss A)">{row.createTime}</Moment>
                                        </td>
                                        {/* Số po */}
                                        <td
                                            onClick={() => handleShowDetail(row.rawMaterialCardId)}
                                            className="py-1.5 px-2 border text-sm whitespace-nowrap text-sky-700 hover:text-sky-600 text-center cursor-pointer"
                                        >
                                            {row.purchaseOrderCode}
                                        </td>
                                        {/* Số So */}
                                        <td className="py-1.5 px-2 border text-sm whitespace-nowrap">{row.sosoLine}</td>
                                        {/* WBS */}
                                        <td className="py-1.5 px-2 border text-sm whitespace-nowrap">{row.wbs}</td>
                                        {/* plant  */}
                                        <td className="py-1.5 px-2 border text-sm whitespace-nowrap">{row.plant}</td>
                                        {/* Ngày chứng từ */}
                                        <td className="py-1.5 px-2 border text-sm whitespace-nowrap">
                                            <Moment format="DD/MM/YYYY">{row.documentDate}</Moment>
                                        </td>
                                        {/* Mã nguyên vật liệu */}
                                        <td className="py-1.5 px-2 border text-sm whitespace-nowrap">
                                            {row.productCode}
                                        </td>
                                        {/* Tên nguyên vật liệu */}
                                        <td className="py-1.5 px-2 border text-sm whitespace-nowrap">
                                            {row.productName}
                                        </td>
                                        {/* Số lượng đặt hàng */}
                                        <td className="py-1.5 px-2 border text-sm whitespace-nowrap">
                                            {row.poQuantity}
                                        </td>
                                        {/* Số lượng đã giao */}
                                        <td className="py-1.5 px-2 border text-sm whitespace-nowrap">
                                            {row.poQuantity_Delivered}
                                        </td>
                                        {/* Số lượng giao hàng */}
                                        <td className="py-1.5 px-2 border text-sm whitespace-nowrap">{row.quantity}</td>
                                        {/* Đơn vị tính */}
                                        <td className="py-1.5 px-2 border text-sm whitespace-nowrap">
                                            {row.poQuantityUnit}
                                        </td>
                                        {/* Mã nhà cung cấp */}
                                        {/* là tk nhân viên thì hiển thị */}
                                        {!isVendor && (
                                            <td className="py-1.5 px-2 border text-sm whitespace-nowrap">
                                                {row.vendorCode}
                                            </td>
                                        )}
                                        {/* Tên nhà cung cấp */}
                                        {/* là tk nhân viên thì hiển thị */}
                                        {!isVendor && (
                                            <td className="py-1.5 px-2 border text-sm whitespace-nowrap">
                                                {row.vendorName}
                                            </td>
                                        )}
                                        {/* Tình trạng giao hàng */}
                                        <td className="py-1.5 px-2 border text-sm whitespace-nowrap">
                                            {row.isReceive ? 'Đã giao' : 'Đang giao'}
                                        </td>
                                        {/* Thời gian nhập kho */}
                                        <td className="py-1.5 px-2 border text-sm whitespace-nowrap">
                                            {/* <Moment format="DD/MM/YYYY">{row.receiveDate}</Moment> */}
                                        </td>
                                        {/* QC */}
                                        {/* là tk nhân viên thì hiển thị */}
                                        {!isVendor && (
                                            <td className="py-1.5 px-2 border text-sm whitespace-nowrap"></td>
                                        )}
                                        {/* Trạng thái */}
                                        {/* là tk nhân viên thì hiển thị */}
                                        {!isVendor && (
                                            <td className="py-1.5 px-2 border text-sm whitespace-nowrap">
                                                {row.actived ? (
                                                    <FaCheck className="m-auto text-primary" />
                                                ) : (
                                                    <FaTimes className="m-auto text-danger" />
                                                )}
                                            </td>
                                        )}
                                        {/* Chức năng  // xem chi tiết hoặc hủy*/}
                                        {/* Có thể hủy những đơn hàng chưa nhập kho. Nhập kho rồi thì không được hủy */}
                                        <td className="py-1.5 px-2 border text-sm whitespace-nowrap flex justify-start items-center">
                                            <button
                                                onClick={() => handleShowDetail(row.rawMaterialCardId)}
                                                className="flex py-[1px] px-2.5 justify-center items-center bg-info rounded-sm border border-sky-500 text-white text-default hover:bg-sky-500"
                                            >
                                                <FaRegEye className="font-bold mr-0.5" />
                                                <span>Xem</span>
                                                {/*nếu đơn hàng  nhập kho resceive == true thì ẩn nút hủy  */}
                                            </button>
                                            {!row.isReceive && (
                                                <button className="flex py-[1px] px-2.5 justify-center items-center bg-warning rounded-sm border border-orange-600 text-white text-default hover:bg-orange-600 ml-1">
                                                    <FaTimes className="font-bold mr-0.5" />
                                                    Hủy
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <span className="block my-4 text-sm">
                        Đang xem {`${currentPage + 1}`} đến {`${currentPage + pageSize}`} trong tổng số{' '}
                        {`${deliveryHistoryStore.listDeliveryHistory.recordsFiltered}`} mục
                    </span>
                </div>
            </div>
        );
    });
}
