import { post, get } from 'utils/api';

// ========== Models cho trang in barcode
// GetListItemPO
type PoDetail = {
    po: string;
    poLine: string;
};

type SoDetail = {
    so: string;
    soLine: string;
};

type WbsDetail = {
    item: string;
};

export type PurchaseOrderDetails = {
    po: string;
    poLine: string;
    so: string;
    soLine: string;
    wbs: string;
    poQuantity: number;
    quantityReceived: number;
    remainQuantity: number;
    unit: string;
    quantity: number;
};
// GetListItemPO
export type ListItemPO = {
    vendorName: string;
    vendorCode: string;
    poDetails: PoDetail[];
    productName: string;
    productCode: string;
    poQuantity: number;
    poQuantityUnit: string;
    receiveQuantity: number;
    receiveQuantityUnit?: any;
    soDetails: SoDetail[];
    wbsDetails: WbsDetail[];
    purchaseOrderDetails: PurchaseOrderDetails[];
};

export type ListItemPORespone = {
    code: number;
    isSuccess: boolean;
    message?: any;
    data?: ListItemPO;
    additionalData?: any;
};

//========= models save barcode
// output

export type SaveBarcodeRespone = {
    isSuccess: boolean;
    message: string;
    data: string;
};

// get barcode
export type BarcodeDetails = {
    rawMaterialCardCode: number;
    materialType: string;
    vendorCode: string;
    vendorName: string;
    plant: string;
    productCode: string;
    productName: string;
    poQuantity: number;
    poQuantityUnit: string;
    poDetails: PoDetail[];
    soDetails: any[];
    wbsDetails: any[];
    specifications?: any;
    quantity: number;
    unit: string;
    quantity2: number;
    quantity2Unit: string;
    quantity3: number;
    quantity3Unit: string;
    manufacturingDate?: any;
    manufacturingDateStr?: any;
    expirationDate?: any;
    expirationDateStr?: any;
    barcodePath: string;
    createTime: Date;
    createTimeStr: string;
    listLSXDT: string[];
    createBy?: any;
    isReceive: number;
    poDetailResponses: PurchaseOrderDetails[];
    inforReceived: InforReceived;
};

export type BarcodeResponse = {
    code: number;
    isSuccess: boolean;
    message?: any;
    data: BarcodeDetails;
    additionalData?: any;
};

export type InforReceived = {
    batchNumber?: any;
    plant?: any;
    sloc?: any;
    warehouseNo?: any;
    storageBin?: any;
};

//
export type POQuantityRespone = {
    po: string;
    poLine: string;
    quantityImport?: any;
    quantityByPO: number;
    quantityReceived: number;
    unit: string;
};

export interface IBarcodeServices {
    getListItemPO(data: any): Promise<ListItemPORespone>;
    saveBarcode(data: any): Promise<SaveBarcodeRespone>;
    // lấy thông tin barcode theo RawMaterialCardId
    getBarcode(id: string): Promise<BarcodeDetails>;
}

export function createPrintBarCodeServices(url: string): IBarcodeServices {
    return {
        getListItemPO: async (data: any): Promise<ListItemPORespone> => {
            return (await post('MES/PurchaseOrder/GetListItemPO', data)).data;
        },
        saveBarcode: async (data: any): Promise<SaveBarcodeRespone> => {
            return (await post('MES/PurchaseOrder/SaveBarcode', data)).data;
        },
        getBarcode: async (id: string): Promise<BarcodeDetails> => {
            return (await get(`MES/PurchaseOrder/GetBarcode?RawMaterialCardId=${id}`)).data.data;
        },
    };
}
